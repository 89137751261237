import React, { forwardRef, ForwardedRef } from "react";
import Leather, { LeatherProps } from "./Leather";

const RowInner = (props: LeatherProps, ref: ForwardedRef<HTMLDivElement>) => (
  <Leather {...props} ref={ref} />
);

const Row = forwardRef(RowInner) as (
  props: LeatherProps & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof RowInner>;
export default Row;
