import styled from "@emotion/styled";
import useParallax, { ParralaxProps } from "../../../hooks/useParallax";
import React, { CSSProperties, FC } from "react";

export interface ParralaxItemProps {
  paralaxProps?: ParralaxProps;
  backgroundProps?: Pick<
    CSSProperties,
    | "background"
    | "backgroundRepeat"
    | "backgroundSize"
    | "height"
    | "backgroundPosition"
    | "width"
    | "zIndex"
  >;
}

const ParralaxBackground: FC<ParralaxItemProps> = ({
  paralaxProps,
  backgroundProps,
}) => {
  const { ref } = useParallax({
    ...paralaxProps,
    applyAnimation: (element, newX, newY) => {
      element.style.backgroundPosition = `${newX}px ${newY}px`;
    },
  });
  const Background = styled("div")(backgroundProps);
  return <Background ref={ref} />;
};
export default ParralaxBackground;
