import React, { FC } from "react";
import { Col, Row } from "src/shoe-leather/components";
import styled from "@emotion/styled";
import TextFrameLarge from "../ui/animated-svg/TextFrameLarge";
import GlassButton from "../ui/button/TextButton";
import { ROUTES } from "src/App";
import ParralaxItem from "../ui/effects/ParallaxItem";
import Typography from "src/shoe-leather/components/Typography";
import { Fade } from "../ui/animation";
import Heading from "../ui/Heading";

const TitledParagraph = styled(Col)(({ theme }) => ({
  width: 600,
  maxWidth: `calc(100vw - ${theme.dimensions.padding.lg}px * 2)`,
  margin: theme.dimensions.padding.lg,
  gap: 32,
  animationDelay: ".5s",
}));
const NoverFlow = styled(Row)({
  overflowX: "hidden",
  maxWidth: "100%",
});
const ButtonContainer = styled(ParralaxItem)({
  height: 74,
  zIndex: 2,
});

const Home: FC = () => {
  return (
    <>
      <NoverFlow grow center middle>
        <TextFrameLarge />
        <Fade>
          <TitledParagraph center>
            <Heading>Hello</Heading>

            <Typography center>
              Hey there, I&lsquo;m Emerson. A logistical creative mind. I bring
              a wealth of experience and creativity to the digital realm.

            </Typography>
            <Typography center>
              From developing at Exclusive Resorts to gripping and gaffing on
              the set of music videos and short film, I thrive on turning ideas
              into digital reality.
            </Typography>
            <Typography center>
              Welcome to my digital world.
            </Typography>
          </TitledParagraph>
        </Fade>
        <TextFrameLarge right />
      </NoverFlow>
      <Row grow center>
        <ButtonContainer>
          <Row grow>
            <Fade>
              <GlassButton href={ROUTES.PROJECTS}>
                <Typography variant="body">Explore Projects </Typography>
              </GlassButton>
            </Fade>
          </Row>
        </ButtonContainer>
      </Row>
    </>
  );
};
export default Home;
