import React, { ForwardedRef, forwardRef } from "react";
import Leather, { LeatherProps } from "./Leather";

const HeaderInner = (
  props: LeatherProps,
  ref: ForwardedRef<HTMLDivElement>
) => <Leather {...props} component="header" ref={ref} />;

const Header = forwardRef(HeaderInner) as (
  props: LeatherProps & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof HeaderInner>;
export default Header;
