import React, { FC, useMemo } from "react";
import { Col, Row } from "src/shoe-leather/components";
import TextFrameSmall from "../ui/animated-svg/TextFrameSmall";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import ProjectItem, { ProjectItemProps } from "./Projects/ProjectItem";
import Heading from "../ui/Heading";

const NoverFlow = styled(Row)({
  overflowX: "hidden",
  maxWidth: "100%",
});
const ProjectCol = styled(motion.div)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignSelf: "center",
  gap: theme.dimensions.padding.lg,
  margin: theme.dimensions.padding.lg,
  maxWidth: `calc(100vw - ${2 * theme.dimensions.padding.lg}px)`,
  width: theme.dimensions.components.default.maxWidth,
  [theme.breakpoints.down.sm]: {},
}));
const LineColumn = styled(Col)(({ theme }) => ({
  position: "fixed",
  top: `calc(50vh - ${theme.dimensions.components?.header.height}px)`,
  height: "400px",
}));
const LineColumnLeft = styled(LineColumn)(() => ({
  left: 0,
}));
const LineColumnRight = styled(LineColumn)(() => ({
  right: 0,
}));
const projectItems: ProjectItemProps[] = [
  {
    image: "/image/projects/ashfel.png",
    title: "Ashfel.com",
    date: "2023",
    description:
      "A personal protfolio and dockerized deployment of all projects using nginx.",
    to: "/home",
    pills: ["TypeScript", "React", "ThreeJS", "Axios", "Figma"],
  },
  {
    image: "/image/projects/climbgrader.png",
    title: "Climb Grader",
    date: "2022",
    description:
      "Imagine you own a rock climbing gym and you want to encourage your patrons to provide feedback on the climbing routes provided. Climb Grader allows you to put up simple QR-codes next to the climbing route as you normally would with difficulty ratings. These QR codes allow members to virtually review, grade, and ultimately providing you and your route setters valuable gym member feedback on the variety of climbing routes and opportunities in the gym.",
    to: "https://climb-grader.ashfel.com",
    github: "https://github.com/knaxel/Climb-Grader",
    pills: ["MongoDB", "ExpressJS", "Skeleton.css"],
  },
  {
    image: "/image/projects/knaxel.net.png",
    title: "KNAXEL.NET",
    date: "2021",
    description:
      "An online ReactJS persona website. Goal of the project was practicing React again and having fun with WebGL. Pretty much everything about this website is completely customized. Some idea's are from starting places, but I have never seen them combined this way.",
    to: "https://KNAXEL.NET/",
    github: "https://github.com/knaxel/KNAXEL.NET",
    pills: ["React.JS", "Three.JS"],
  },
  {
    image: "/image/projects/voxelengine.png",
    title: "Voxel Game Engine",
    date: "2021",
    description:
      "OpenGL Voxel game-engine typical optimizations. Combines ECS principles with OOP ideas for a nice usage of the engine. Impliments greedy meshing optimization for vertice reduction. Each vertice has data compressed before saved to a VAO in OpenGL and rendered, reducing overall application memory usage. Two rendering pipelines, one is a mesh visualizer/shader.",
    to: "https://github.com/knaxel/Voxel-Engine-Example",
    github: "https://github.com/knaxel/Voxel-Engine-Example",
    pills: ["Java + LWJGL", "OpenGL / GLSL", "ECS"],
  },
  {
    image: "/image/projects/n-body.png",
    title: "N-Body Simulation",
    date: "2021",
    description: "A naive approach to 3d n-body simulation using WebGL. The simulation is based on the gravitational forces between the particles. The particles are rendered as spheres and the forces are calculated using Newton's law of universal gravitation. The simulation is done in real-time and the user can interact with the particles using the mouse.",
    to: "https://n-body.ashfel.com",
    pills: ["WebGL", "CSS3", "JavaScript"],
  },
  {
    image: "/image/projects/codigram.png",
    title: "Dynamicode",
    date: "2020",
    description:
      "A online learning web application my friends and I developed over a summer at CU. Including a Python editor using Skult. Users can create secure accounts and post code snippets of Python code. There are several learning Modules that the users can complete on their way to understanding more advanced programming topics.",
    to: "https://dynamicode.ashfel.com",
    github: "https://github.com/knaxel/dynamicode",
    pills: ["Flask", "PostgreSQL", "Skulpt", "Heroku", "Bootstrap"],
  },
  {
    image: "/image/projects/jimmysmaze.png",
    title: "Jimmy's Maze",
    date: "2019",
    description:
      "Its a JavaScript game about being in a maze with back-end. There are seven maze levels to complete and the user's score is determined by the time taken to complete the maze. The game includes a leaderboard, profile page, and user login and registration features.",
    to: "https://jimmysmaze.ashfel.com",
    github: "https://github.com/knaxel/JimmysMaze",
    pills: ["PHP", "MySQL", "Bootstrap"],
  },
];
const Projects: FC = () => {
  const items = useMemo(
    () =>
      projectItems.map((item) => (
        <ProjectItem
          key={"projects-" + new Date().getTime() + Math.random() * 4000}
          {...item}
        />
      )),
    [projectItems]
  );
  return (
    <NoverFlow grow center>
      <Col>
        <Row grow>
          <LineColumnLeft grow>
            <TextFrameSmall />
          </LineColumnLeft>
          <ProjectCol
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1, duration: 0.5 } }}
            exit={{ opacity: 0 }}
          >
            <Heading>Projects</Heading>
            {items}
          </ProjectCol>
          <LineColumnRight grow>
            <TextFrameSmall right />
          </LineColumnRight>
        </Row>
      </Col>
    </NoverFlow>
  );
};
export default Projects;
