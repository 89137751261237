import styled from "@emotion/styled";
import React, { ForwardedRef, forwardRef } from "react";
import { ReactNode } from "react";
import { Col } from "src/shoe-leather/components";

const StyledList = styled("ul")(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 0.8,
  padding: 0,
  margin: 0,
}));

const Divider = styled("hr")(() => ({
  color: "var(--font-color)",
  margin: 0,
}));
interface ListProps {
  children: ReactNode | ReactNode[];
}
const ListInner = (
  { children, ...other }: ListProps,
  ref: ForwardedRef<HTMLUListElement>
) => {
  return (
    <StyledList {...other} ref={ref}>
      {Array.isArray(children)
        ? children.map((child, idx) => {
            return (
              <Col key={"list-" + new Date().getTime() + Math.random() + idx}>
                {child}
                {idx < children.length - 1 && <Divider />}
              </Col>
            );
          })
        : children}
    </StyledList>
  );
};

const List = forwardRef(ListInner) as (
  props: ListProps & { ref?: ForwardedRef<HTMLUListElement> }
) => ReturnType<typeof ListInner>;
export default List;
