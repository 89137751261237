import React, { ReactNode, useRef } from "react";
import { FC } from "react";
import Typography from "src/shoe-leather/components/Typography";
import { Row } from "src/shoe-leather/components";
import TitleFrame from "./animated-svg/TextHeader";
import { LeatherProps } from "src/shoe-leather/components/Leather";
import styled from "@emotion/styled";

const StyledRow = styled(Row)(({ theme }) => ({
  gap: theme.dimensions.padding.lg,
  maxWidth: "100vw",
}));
const Heading: FC<{ children: ReactNode } & LeatherProps> = ({
  children,
  ...other
}) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const gap = (ref.current?.getBoundingClientRect().width ?? 100) + 64;
  return (
    <StyledRow {...other}>
      <TitleFrame gap={gap} />
      <Typography center variant="title2" ref={ref}>
        {children}
      </Typography>
      <TitleFrame right gap={gap} />
    </StyledRow>
  );
};

export default Heading;
