import { Theme, useTheme } from "@emotion/react";
import { motion } from "framer-motion";
import React, { FC } from "react";
import useWindowSize from "src/hooks/useWindowSize";
import { Col } from "src/shoe-leather/components";
import { getStrokeAnimations } from ".";

export const getLineSpeed = (lineLength: number): number => {
  return Math.max(lineLength * 2, 900);
};
export const CURVE_LENGTH = 243;
export const CURVE_WIDTH = 168;
export const getLineLength = (innerWidth: number, theme: Theme): number => {
  const maxSvgWidth =
    (innerWidth -
      theme.dimensions.components.default.maxWidth -
      theme.dimensions.padding.lg * 2) /
    2;
  return Math.max(maxSvgWidth - CURVE_WIDTH, 1);
};
const TextFrameSmall: FC<{ right?: boolean }> = ({ right = false }) => {
  const theme = useTheme();
  const [innerWidth, _] = useWindowSize();
  const lineLength = getLineLength(innerWidth, theme);
  const lineSpeed = getLineSpeed(lineLength);
  const lineTime = lineLength / lineSpeed;
  const curveTime = CURVE_LENGTH / lineSpeed;
  const totalWidth = lineLength + CURVE_WIDTH + 3;
  const overflowWidth =
    (innerWidth -
      theme.dimensions.components.default.maxWidth -
      theme.dimensions.padding.lg * 2) /
    2;
  return (
    <Col
      key="tesT"
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        transform: right ? "scale(-1,1)" : "",
        maxWidth: Math.max(0, overflowWidth),
        overflowX: "hidden",
      }}
    >
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width={totalWidth}
        height="244"
        viewBox={`0 0 ${totalWidth} 244`}
        fill="none"
        style={{ minWidth: "fit-content" }}
      >
        <motion.path
          d={`M${lineLength} 122C${119 + lineLength} 122 ${
            168 + lineLength
          }  86.5 ${168 + lineLength} 1`}
          stroke="var(--font-color)"
          strokeWidth={2}
          strokeLinecap="round"
          {...getStrokeAnimations({
            length: CURVE_LENGTH,
            duration: curveTime,
            delayAnimate: lineTime,
            delayExit: 0,
            easeAnimate: "easeOut",
            easeExit: "easeIn",
          })}
        />
        <motion.path
          d={`
          M${lineLength} 122C${119 + lineLength} 122 ${
            168 + lineLength
          } 157.5 ${168 + lineLength} 243`}
          stroke="var(--font-color)"
          strokeWidth={2}
          strokeLinecap="round"
          {...getStrokeAnimations({
            length: CURVE_LENGTH,
            duration: curveTime,
            delayAnimate: lineTime,
            delayExit: 0,
            easeAnimate: "easeOut",
            easeExit: "easeIn",
          })}
        />
        {/* line */}
        <motion.path
          d={`M0 122H${lineLength}`}
          stroke="var(--font-color)"
          strokeWidth={2}
          strokeLinecap="round"
          {...getStrokeAnimations({
            length: lineLength,
            duration: lineTime,
            delayAnimate: 0,
            delayExit: curveTime,
            easeAnimate: "easeIn",
            easeExit: "easeOut",
          })}
        />
      </motion.svg>
    </Col>
  );
};
export default TextFrameSmall;
