import React, { ForwardedRef, forwardRef } from "react";
import Leather, { LeatherProps } from "./Leather";
import { TypographyVariant } from "src/emotion";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

export interface TypographyProps {
  variant?: TypographyVariant;
}
const TypographyInner = (
  props: LeatherProps & TypographyProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const theme = useTheme();
  const { variant, ...leather } = props;
  const { type, ...styles } = theme.typography[variant ?? "body"];
  const TypographyStyled = styled(Leather)(({ theme }) => ({
    ...styles,
    [theme.breakpoints.down.sm]: {
      fontSize: theme.typography[variant ?? "body"].fontSizeMobile ?? theme.typography[variant ?? "body"].fontSize,
    },
  }));
  return <TypographyStyled component={type} {...leather} ref={ref} />;
};

const Typography = forwardRef(TypographyInner) as (
  props: TypographyProps &
    LeatherProps & {
      ref?: ForwardedRef<HTMLDivElement>;
    }
) => ReturnType<typeof TypographyInner>;

export default Typography;
