import styled from "@emotion/styled";

const Button = styled("button")({
  cursor: "pointer",
  display: "flex",
  position: "relative",
  justifyContent: "center",
  border: "none",
  width: "fit-content",
  height: "fit-content",
  padding: 0,

  fontWeight: "500",

  transition: "all 500ms ease",
  background: "transparent",
});

export default Button;
