// Collapse.tsx
import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Col, Row } from "src/shoe-leather/components";

const BlurBackground = styled(Col)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 3,
  background: "rgba(8, 8, 8, 0.8)",
  backdropFilter: "blur(8px)",
  padding: theme?.dimensions.padding.lg,
  opacity: "0",
  visibility: "hidden",
  transition: `opacity 500ms ease,  visibility 0ms 500ms ease`,
  "&.visible": {
    opacity: "1",
    visibility: "visible",
    transition: `opacity 500ms ease, visibility 0ms 0ms ease`,
  },
}));
const ContentWrapper = styled(Row)({
  width: "100%",
  height: "100%",
  zIndex: 1000,
  justifyContent: "center",
  alignItems: "center",
});

interface CollapseProps {
  isVisible: boolean;
  children: ReactNode;
}
const Collapse: React.FC<CollapseProps> = ({ isVisible, children }) => {
  return (
    <>
      <motion.div
        exit={{ transition: { opacity: 0, delay: 700, duration: 500 } }}
      >
        <BlurBackground className={isVisible ? "visible" : ""}>
          <ContentWrapper>{children}</ContentWrapper>
        </BlurBackground>
      </motion.div>
    </>
  );
};

export default Collapse;
