import React from "react";
import { FC } from "react";
import { Footer as LeatherFooter } from "src/shoe-leather/components";
import WindowAnimation from "../ui/animation/Window";
import styled from "@emotion/styled";

const StyledFooter = styled(LeatherFooter)(({ theme }) => ({
  height: theme.dimensions.components.footer.height,
  maxHeight: theme.dimensions.components?.header.height,
  paddingTop: theme.dimensions.padding.lg,
}));
const Footer: FC = () => {
  return (
    <StyledFooter center>
      <WindowAnimation from="bottom">
        <p>
          Created by Emerson{" "}
          <a href="http://knaxel.net">
            <code> @KNAXEL </code>
          </a>
        </p>
      </WindowAnimation>
    </StyledFooter>
  );
};
export default Footer;
