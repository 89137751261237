import styled from "@emotion/styled";
import React, { ButtonHTMLAttributes, FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import {
  Blur,
  HorizonalLines,
  Shine,
  VerticalLines,
} from "../effects/SpanEffects";
import Button from "./Button";

const StyledButton = styled(Button)(() => ({
  fontWeight: "500",
  lineHeight: "42px",
}));
export interface GlassButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string;
  children?: ReactNode;
}
const PaddedShine = styled(Shine)(({ theme }) => ({
  padding: `${theme.dimensions.padding.sm}px ${theme.dimensions.padding.lg}px`,
}));
const TextButton: FC<GlassButtonProps> = ({ href, children, ...rest }) => {
  const renderedButton = (
    <StyledButton {...rest}>
      <Blur>
        <VerticalLines>
          <HorizonalLines>
            <PaddedShine>{children}</PaddedShine>
          </HorizonalLines>
        </VerticalLines>
      </Blur>
    </StyledButton>
  );
  if (href) {
    return <Link to={href}>{renderedButton}</Link>;
  }
  return renderedButton;
};
export default TextButton;
