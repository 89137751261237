import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

export const VerticalLines = styled("span")({
  width: "100%",
  height: "100%",

  "&:before": {
    right: "0",
    top: "0",
  },
  "&:after": {
    left: "0",
    bottom: "0",
  },
  "&:before, &:after": {
    position: "absolute",
    content: '""',
    // height: "0%",
    width: "2px",
    background: "var(--font-color)",
    transition: "all 500ms ease",
    height: "0%",
  },
  "&:active, &:hover": {
    "&:before, &:after": {
      height: "100%",
    },
  },
});
export const HorizonalLines = styled("span")({
  width: "100%",
  height: "100%",
  "&:before": {
    left: "0",
    top: "0",
  },
  "&:after": {
    right: "0",
    bottom: "0",
  },
  "&:before, &:after": {
    position: "absolute",
    content: '""',
    width: "0%",
    height: "2px",
    background: "var(--font-color)",
    transition: "all 500ms ease",
  },
  "&:active, &:hover": {
    "&:before, &:after": {
      width: "100%",
    },
  },
});
export const shiny = keyframes({
  " 0%": { transform: "scale(4) rotate(45deg); opacity: 1" },
  "10%": { transform: "scale(4) rotate(45deg); opacity: 1" },
  "50%": { transform: "scale(50) rotate(45deg); opacity: 0" },
  "100%": { transform: "scale(50) rotate(45deg); opacity: 0" },
});
export const Shine = styled("span")({
  display: "flex",
  position: "relative",
  overflow: "hidden",
  "&:hover": {
    background: "var(--background-color)",
    color: "#fff",
  },
  "&:before": {
    position: "absolute",
    content: '""',
    display: "inline-block",
    top: -180,
    left: 0,
    width: 30,
    height: "100%",
    backgroundColor: "#fff",
  },
  " &:active:before, &:hover:before": {
    animation: `${shiny} 1s ease-in-out infinite`,
  },
});
export const Blur = styled("span")({
  backdropFilter: "blur(3px)",
});
