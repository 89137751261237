export interface StrokeAnimationOptions {
  length: number;
  duration?: number;
  delayAnimate?: number;
  delayExit?: number;
  easeAnimate?: string;
  easeExit?: string;
}
export const getStrokeAnimations = ({
  length = 0,
  delayAnimate = 0,
  delayExit = 0,
  duration = 1,
  easeAnimate = "easeIn",
  easeExit = "easeIn",
}: StrokeAnimationOptions) => {
  return {
    initial: {
      strokeDasharray: length,
      strokeDashoffset: length,
    },
    animate: {
      strokeDasharray: length,
      strokeDashoffset: 0,
      transition: {
        delay: delayAnimate,
        duration: duration,
        ease: easeAnimate,
        strokeDasharray: {
          duration: 0,
        },
      },
    },
    exit: {
      strokeDasharray: length,
      strokeDashoffset: length,
      transition: {
        delay: delayExit / 2,
        duration: duration / 2,
        ease: easeExit,
        strokeDasharray: {
          duration: 0,
        },
      },
    },
  };
};
