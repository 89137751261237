import styled from "@emotion/styled";
import React, { FC, useCallback, useState } from "react";

import {
  Col,
  Row,
  Header as LeatherHeader,
} from "../../shoe-leather/components";
import Title from "../ui/Title";
import ParralaxItem from "../ui/effects/ParallaxItem";
import { ROUTES } from "src/App";
import { Link } from "react-router-dom";
import GlassButton from "../ui/button/TextButton";
import useWindowSize from "src/hooks/useWindowSize";
import { useTheme } from "@emotion/react";
import IconButton from "../ui/button/IconButton";
import Collapse from "../ui/Collapse";
import Nav from "./Nav";
import { HamburgerIcon, QRCodeIcon } from "../ui/icons";

const HeaderGlassBox = styled(Col)(({ theme }) => ({
  position: "relative",
  maxWidth: 300,
  borderRight: `2px solid ${theme.colors.font}`,
  borderLeft: `2px solid ${theme.colors.font}`,
  backdropFilter: "blur(3px)",
  transition: "all 500ms ease",
  height: "fit-content",
  textAlign: "center",
  flexShrink: 1,
  padding: theme.dimensions.padding.sm,
  [theme.breakpoints.up.sm]: {
    padding: "4px 36px 16px 36px",
    whiteSpace: "nowrap",
  },
}));
const GappedRow = styled(Row)(({ theme }) => ({
  transition: "all 500ms ease",
  gap: theme.dimensions.padding.sm,
}));
const HeaderContainer = styled(LeatherHeader)((props) => ({
  height: props.theme.dimensions.components?.header.height,
  top: 0,
  zIndex: 4,
  [props.theme.breakpoints.down.sm]: {
    padding: `0 ${props.theme.dimensions.padding.sm}px`,
  },
}));
const LiftedParralaxItem = styled(ParralaxItem)({
  zIndex: 3,
});
const HeaderTitleNav = styled(Col)(({ theme }) => ({
  position: "relative",
  left: "0%",
  transform: "unset",
  [theme.breakpoints.up.sm]: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
}));
const NavItem = styled(Col)(({ theme }) => ({
  alignItems: "center",
  flexGrow: 1,
  [theme.breakpoints.up.sm]: {
    alignItems: "unset",
    maxWidth: 54 + 16,
  },
  minWidth: 36,
}));

const Header: FC = () => {
  const [innerWidth, _] = useWindowSize();
  const theme = useTheme();
  const isMobile = innerWidth < theme.breakpoints.sm;
  const [isVisible, setVisible] = useState<boolean>(false);
  const toggleCollapse = useCallback(() => {
    setVisible(!isVisible);
  }, [isVisible]);
  const hideCollapse = () => setVisible(false);
  return (
    <>
      <HeaderContainer wide spread>
        <NavItem middle center>
          {isMobile && (
            <IconButton onClick={toggleCollapse}>
              <HamburgerIcon />
            </IconButton>
          )}
        </NavItem>
        <HeaderTitleNav grow center>
          <LiftedParralaxItem
            center
            paralaxProps={{ scaleX: 20, scaleY: 4, offsetY: 0 }}
          >
            <GappedRow grow middle>
              {!isMobile && (
                <GlassButton href={ROUTES.WEATHER}>Weather</GlassButton>
              )}
              <Link to={ROUTES.HOME} onClick={hideCollapse}>
                <HeaderGlassBox>
                  <Title center>Ashfel</Title>
                  <Row center>
                    <p>A portfolio by Emerson</p>
                  </Row>
                </HeaderGlassBox>
              </Link>
              {!isMobile && (
                <GlassButton href={ROUTES.PROJECTS}>Projects</GlassButton>
              )}
            </GappedRow>
          </LiftedParralaxItem>
        </HeaderTitleNav>

        <NavItem middle>
          <IconButton href={ROUTES.LANDING} onClick={hideCollapse}>
            <QRCodeIcon />
          </IconButton>
        </NavItem>
      </HeaderContainer>
      <Collapse isVisible={isVisible}>
        <Nav isVisible={isVisible} close={() => setVisible(false)} />
      </Collapse>
    </>
  );
};
export default Header;
