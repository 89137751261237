import React, { FC } from "react";
import LeatherTitle from "../../shoe-leather/components/Typography";
import styled from "@emotion/styled";
import { LeatherProps } from "src/shoe-leather/components/Leather";

const StyledTitle = styled(LeatherTitle)(() => ({
  marginBottom: "4px",
}));
const Title: FC<LeatherProps> = ({ children, ...rest }) => {
  return (
    <StyledTitle variant="title" {...rest}>
      {children}
    </StyledTitle>
  );
};
export default Title;
