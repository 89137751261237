import React, { useEffect, useRef } from "react";
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "src/App";
import { Row } from "src/shoe-leather/components";
import List from "../ui/List";
import { useTheme } from "@emotion/react";

const Nav: FC<{ isVisible?: boolean; close?: () => void }> = ({
  isVisible,
  close,
}) => {
  const location = useLocation();
  const theme = useTheme();
  const contentRef = useRef<HTMLUListElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isVisible &&
        contentRef.current &&
        !contentRef.current.contains(event.target as Element)
      ) {
        close?.();
      }
    };
    const handleTouchOutside = (event: TouchEvent) => {
      if (
        isVisible &&
        contentRef.current &&
        !contentRef.current.contains(event.target as Node)
      ) {
        close?.();
      }
    };
    document.addEventListener("mouseup", handleClickOutside);
    document.addEventListener("touchend", handleTouchOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
      document.removeEventListener("touchend", handleTouchOutside);
    };
  }, [contentRef]);
  return (
    <List ref={contentRef}>
      {Object.keys(ROUTES).map((route, index) => {
        const value = Object.values(ROUTES)[index];
        const label =
          route.charAt(0).toUpperCase() + route.slice(1).toLowerCase();
        return (
          <Row key={new Date().getTime() + index}>
            <Link
              to={value}
              onClick={close}
              style={{ width: "100%", padding: theme.dimensions.padding.lg }}
            >
              {label}
              {location.pathname === route ? " -" : ""}
            </Link>
          </Row>
        );
      })}
    </List>
  );
};
export default Nav;
