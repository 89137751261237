import React, { useEffect } from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "./components/pages/Home";
import Weather from "./components/pages/Weather";
import Footer from "./components/global/Footer";
import Header from "./components/global/Header";
import PageNotFound from "./components/pages/PageNotFound";
import Projects from "./components/pages/Projects";
import AppTheme from "./AppTheme";
import Card from "./components/pages/Card";

declare module "@emotion/react" {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export interface Theme extends Record<string, any> { }
}
export const ROUTES = {
  HOME: "/home",
  WEATHER: "/weather",
  PROJECTS: "/projects",
  LANDING: "/",
};

function App() {
  const location = useLocation();

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const root = document.getElementById("root");
      if (!root) {
        return;
      }
      const boundingClientRect = root.getBoundingClientRect();

      const mouseX = event.pageX / boundingClientRect.width;
      const mouseY = event.pageY / boundingClientRect.height;
      //ff8a00
      const gradient = `radial-gradient(circle at ${mouseX * 100}% ${mouseY * 100
        }%, #191816 0%, var(--background) 20%)`;

      root.style.background = gradient;
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <AppTheme>
      <Header />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <>
                <Outlet />
                <Footer />
              </>
            }
          >
            <Route path={ROUTES.LANDING} element={<Card />} />
            <Route path={ROUTES.WEATHER} element={<Weather />} />
            <Route path={ROUTES.HOME} index element={<Home />} />
            <Route path={ROUTES.PROJECTS} index element={<Projects />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </AppTheme>
  );
}

export default App;
