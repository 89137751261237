import React, { forwardRef, ForwardedRef } from "react";
import Leather, { LeatherProps } from "./Leather";

const ColInner = (props: LeatherProps, ref: ForwardedRef<HTMLDivElement>) => (
  <Leather isCol {...props} ref={ref} />
);

const Col = forwardRef(ColInner) as (
  props: LeatherProps & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof ColInner>;
export default Col;
