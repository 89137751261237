import { RefObject, useEffect, useRef } from 'react';
import useWindowSize from './useWindowSize';
const SMOOTHING = 25;
export interface ParralaxProps {
    scaleX?: number;
    scaleY?: number;
    offsetX?: number;
    offsetY?: number;
    applyAnimation?: (element: HTMLDivElement, newX: number, newY: number) => void;
}
export interface ParralaxReturn {
    ref: RefObject<HTMLDivElement>
}
const defaultCallback = (element: HTMLDivElement, newX: number, newY: number) => {
  element.style.transform = `translate(${newX}px, ${newY}px) `;
};
const useParallax = (props?: ParralaxProps) => {
  const {
    scaleX = 50,
    scaleY = 8,
    offsetX = 0,
    offsetY = 0,
    applyAnimation = defaultCallback,
  } = props ?? {};
  const [innerWidth, innerHeight] = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);
  let finalX = offsetX;
  let finalY = offsetY;
  let currentX = offsetX;
  let currentY = offsetY;
  useEffect(() => {
    if (ref.current) {
      applyAnimation(ref.current, currentX, currentY);
    }

    const handleMouseMove = (event: MouseEvent) => {
      finalX = -(event.clientX / innerWidth * 2 - 1) * scaleX + offsetX;
      finalY = -(event.clientY / innerHeight * 2 - 1) * scaleY + offsetY;
    };
    const onTouchMove = (event:TouchEvent) => {
      finalX = -(event.changedTouches[event.changedTouches.length - 1].clientX / innerWidth * 2 - 1) * scaleX + offsetX;
      finalY = -(event.changedTouches[event.changedTouches.length - 1].clientY / innerHeight * 2 - 1) * scaleY + offsetY;
    };
    const animate = () => {
      if (!ref.current) { return; }
      const newX = (finalX - currentX) / SMOOTHING + currentX;
      const newY = (finalY - currentY) / SMOOTHING + currentY;
      if (Math.abs(newX - currentX) > 0.0001) {
        applyAnimation(ref.current, Math.round(newX*1000)/1000, Math.round(newY*1000)/1000);
      }
      requestAnimationFrame(animate);
      currentX = newX;
      currentY = newY;
    };
    animate();
    if (innerWidth > 768) {
      window.addEventListener('touchmove', onTouchMove);
      window.addEventListener('mousemove', handleMouseMove);
    } else {
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('mousemove', handleMouseMove);
    }
    return () => {
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [ref, innerWidth]);
  return { ref };
};
export default useParallax;
