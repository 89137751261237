import React, { FC, useMemo } from "react";
import ParralaxItem from "../ui/effects/ParallaxItem";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import useWeatherData from "src/hooks/useWeatherData";
import { Fade } from "../ui/animation/";
import { Col, Row } from "src/shoe-leather/components";
import ParralaxBackground from "../ui/effects/ParallaxBackground";

const animateInGrow = () => keyframes`
  from {
    transform: scale(0);
    transform-box: fill-box;
    transform-origin: 50% bottom;
  }
  to {    
    transform: scale(1);
    transform-box: fill-box;
    transform-origin: 50% bottom;
  }
`;
const Tree = styled("img")({
  transform: "scale(0)",
  height: "300px ",
  width: "300px ",
  animation: `${animateInGrow()} .5s linear forwards`,
});
function random2() {
  const num = Math.random();
  const res = num; // Math.pow(num,2);
  return Math.random() < 0.5 ? 1 - res : -(1 - res);
}
const WeatherData = styled(Row)(({ theme }) => ({
  paddingLeft: theme.dimensions.padding.lg,
}));
const NoverFlow = styled(Col)({
  overflowX: "hidden",
  flexGrow: 1,
  maxWidth: "100%",
});

const Weather: FC = () => {
  const { weatherData, location } = useWeatherData();
  const start = new Date().getTime();
  const trees = useMemo(
    () =>
      Array.from({ length: (30 * window.innerWidth) / 2560 }, (_, idx) => {
        const seed = random2(); // (Math.random()*2-1)
        const size =
          (Math.random() * 0.5 + 0.5) * Math.max(Math.abs(seed), 0.4) * 500; // Math.random()*Math.abs(seed)*400;
        const offsetX =
          (seed * window.innerWidth) / 2 + window.innerWidth / 2 - size / 2;
        const props = {
          scaleX: (50 * size) / 400,
          scaleY: (8 * size) / 400,
          offsetX,
          offsetY: -size + 70,
        };
        return (
          <ParralaxItem
            key={"trees-" + idx + new Date().getTime()}
            noSpace
            paralaxProps={props}
          >
            <Tree
              src="../image/weather/tree.png"
              style={{
                animationDelay: `${
                  Math.random() * 500 + (start - new Date().getTime() + 500)
                }ms`,
                height: `${size}px`,
                width: `${size}px`,
              }}
            />
          </ParralaxItem>
        );
      }),
    []
  );

  return (
    <NoverFlow>
      <WeatherData grow>
        <Fade isCol>
          {weatherData ? (
            <Col center>
              <h2>Weather Information</h2>
              <p>
                Based on: (lat:{" "}
                {Math.round((location?.latitude ?? 0) * 10) / 10}°, long:{" "}
                {Math.round((location?.longitude ?? 0) * 10) / 10}°)
              </p>
              <p>
                Temperature: {weatherData.current.temperature_2m}
                {weatherData.hourly_units.temperature_2m}
              </p>
              <p>
                Relative Humidity: {weatherData.current.relative_humidity_2m}
                {weatherData.hourly_units.relative_humidity_2m}
              </p>
              <p>
                Wind Speed: {weatherData.current.wind_speed_10m}{" "}
                {weatherData.hourly_units.wind_speed_10m}
              </p>
            </Col>
          ) : (
            <p>Loading weather data...</p>
          )}
        </Fade>
      </WeatherData>
      <Fade isCol>
        {trees}
        <ParralaxBackground
          paralaxProps={{ offsetY: -50 }}
          backgroundProps={{
            background: 'url("../image/weather/grass.png")',
            backgroundRepeat: "repeat-x",
            backgroundSize: "1500px",
            height: "70px ",
            backgroundPosition: `0-50px`,
            width: "100%",
            zIndex: 2,
          }}
        />
        <ParralaxBackground
          paralaxProps={{ scaleX: 90, scaleY: 10, offsetY: -10 }}
          backgroundProps={{
            background: 'url("../image/weather/stone.png")',
            backgroundRepeat: "repeat-x",
            backgroundPosition: `0 -10px`,
            backgroundSize: "1500px",
            height: "100px",
            width: "100%",
            zIndex: 2,
          }}
        />
      </Fade>
    </NoverFlow>
  );
};

export default Weather;
