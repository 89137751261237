import { Col } from "../../../shoe-leather/components";
import useParallax, { ParralaxProps } from "../../../hooks/useParallax";
import React, { FC, ReactNode } from "react";
import { LeatherProps } from "src/shoe-leather/components/Leather";

export interface ParralaxItemProps {
  paralaxProps?: ParralaxProps;
  noSpace?: boolean;
  children?: ReactNode;
}
const ParralaxItem: FC<ParralaxItemProps & LeatherProps> = ({
  paralaxProps,
  noSpace = false,
  children = undefined,
  ...rest
}) => {
  const { ref } = useParallax({ ...paralaxProps });
  if (noSpace) {
    return (
      <div style={{ position: "relative", width: "0", height: "0" }}>
        <div ref={ref} {...rest}>
          {children}
        </div>
      </div>
    );
  } else {
    return (
      <Col ref={ref} {...rest}>
        {children}
      </Col>
    );
  }
};
export default ParralaxItem;
