import { motion } from "framer-motion";
import React from "react";
import { FC, ReactNode } from "react";

const Fade: FC<{
  duration?: number;
  delay?: number;
  isCol?: boolean;
  children?: ReactNode;
}> = ({ isCol = true, duration = 0.5, delay = 0, children, ...rest }) => {
  return (
    <motion.div
      className={isCol ? "col" : "row"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration, delay } }}
      exit={{ opacity: 0 }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};
export default Fade;
