import { useTheme } from "@emotion/react";
import { motion } from "framer-motion";
import React, { FC, useMemo } from "react";
import useWindowSize from "src/hooks/useWindowSize";
import { getStrokeAnimations } from ".";

export const CURVE_WIDTH = 212;
export const CURVE_LENGTH = 345;
export const MINI_CURVE_WIDTH = 38 + 32; // width + offset
export const MINI_CURVE_LENGTH = 81;

export const getLineSpeed = (lineLength: number): number => {
  return Math.max(lineLength * 2, 900);
};
export const getLineLength = (innerWidth: number): number => {
  const maxSvgWidth = (innerWidth - 664) / 2;
  const otherSVGWidth = CURVE_WIDTH + MINI_CURVE_WIDTH;
  return Math.max(maxSvgWidth - otherSVGWidth, 1);
};

const TextFrameLarge: FC<{ right?: boolean }> = ({ right = false }) => {
  const [innerWidth, _] = useWindowSize();
  const theme = useTheme();
  const color = theme.colors.font;

  const otherSVGWidth = CURVE_WIDTH + MINI_CURVE_WIDTH;
  const lineLength = getLineLength(innerWidth);
  const totalWidth = lineLength + otherSVGWidth;
  // const overflowWidth = (innerWidth - 664) / 2;

  const lineSpeed = getLineSpeed(lineLength);
  const lineTime = useMemo(() => lineLength / lineSpeed, []);
  const curveTime = useMemo(() => CURVE_LENGTH / lineSpeed, []);

  const svg = useMemo(() => {
    return (
      <div
        style={{
          transform: right ? "scale(-1, 1)" : "",
          display: "flex",
          flexDirection: "row-reverse",
          flexGrow: 1,
          // maxWidth: Math.max(0, overflowWidth),
          overflow: "hidden",
          alignSelf: "center",
        }}
      >
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width={totalWidth}
          height={423}
          style={{
            flexGrow: 1,
            alignSelf: "center",
            minWidth: "fit-content",
          }}
          viewBox={`0 0 ${totalWidth + 1} 423`}
          fill="none"
        >
          <motion.path
            {...getStrokeAnimations({
              length: lineLength,
              delayAnimate: 0,
              delayExit: curveTime,
              duration: lineTime,
              easeAnimate: "easeIn",
              easeExit: "easeOut",
            })}
            d={`M0 212L${lineLength} 212`}
            stroke={color}
            strokeWidth={2}
            strokeLinecap="round"
          />
          {/* Large Curve */}
          <motion.path
            {...getStrokeAnimations({
              length: CURVE_LENGTH,
              delayAnimate: lineTime,
              delayExit: 0,
              duration: curveTime,
              easeAnimate: "easeOut",
              easeExit: "easeIn",
            })}
            d={`M${lineLength} 212C${186 + lineLength} 212 ${
              218.167 + lineLength
            } 71.3333 ${211 + lineLength} 1`}
            strokeWidth={2}
            stroke={color}
            strokeLinecap="round"
          />
          <motion.path
            {...getStrokeAnimations({
              length: CURVE_LENGTH,
              delayAnimate: lineTime,
              delayExit: 0,
              duration: curveTime,
              easeAnimate: "easeOut",
              easeExit: "easeIn",
            })}
            d={`M${lineLength} 212C${186 + lineLength} 212 ${
              218.167 + lineLength
            } 352.667 ${211 + lineLength} 423`}
            strokeWidth={2}
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {/* Mini Curve */}
          <motion.path
            {...getStrokeAnimations({
              length: MINI_CURVE_LENGTH,
              delayAnimate: lineTime + curveTime / 2,
              delayExit: 0,
              duration: curveTime,
              easeAnimate: "easeOut",
              easeExit: "easeIn",
            })}
            d={`M${239 + lineLength} 212C${273 + lineLength} 193 ${
              276 + lineLength
            } 160 ${277 + lineLength} 146L${277 + lineLength} 146`}
            strokeWidth={2}
            stroke="#F5F5F5"
            strokeLinecap="round"
          />
          <motion.path
            {...getStrokeAnimations({
              length: MINI_CURVE_LENGTH,
              delayAnimate: lineTime + curveTime / 2,
              delayExit: 0,
              duration: curveTime,
              easeAnimate: "easeOut",
              easeExit: "easeIn",
            })}
            d={`M${239 + lineLength} 212C${273 + lineLength} 231 ${
              276 + lineLength
            } 264 ${277 + lineLength} 278L${277 + lineLength} 278`}
            strokeWidth={2}
            stroke="#F5F5F5"
            strokeLinecap="round"
          />
        </motion.svg>
      </div>
    );
  }, [lineLength, innerWidth]);
  return svg;
};
export default TextFrameLarge;
