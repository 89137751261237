import React, { ElementType, forwardRef, ReactNode, ForwardedRef } from "react";

export type FlexOptions = {
  grow?: boolean;
  center?: boolean;
  middle?: boolean;
  spread?: boolean;
  wide?: boolean;
  wrap?: boolean;
};
export const flexOptionsList: string[] = [
  "grow",
  "center",
  "middle",
  "spread",
  "wide",
  "wrap",
];

export type LeatherProps = FlexOptions & {
  isCol?: boolean;
  component?: ElementType;
  className?: string;
  children?: ReactNode;
  [key: string]: unknown;
};

function LeatherInner(
  {
    isCol = false,
    component: Component = "div",
    className = "",
    children,
    ...other
  }: LeatherProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const classNames = [
    ...Object.entries(other)
      .filter(([key]) => flexOptionsList.includes(key))
      .map(([key]) => key.toString()),
  ].join(" ");
  const {
    center: _center,
    grow: _grow,
    middle: _middle,
    spread: _spread,
    wide: _wide,
    wrap: _wrap,
    ...others
  } = other;
  return (
    <Component
      ref={ref}
      className={`${isCol ? "col" : "row"} ${classNames} ${className}`}
      {...others}
    >
      {children}
    </Component>
  );
}

const Leather = forwardRef(LeatherInner) as (
  props: LeatherProps & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof LeatherInner>;

export default Leather;
