import { motion } from "framer-motion";
import React, { FC, useMemo } from "react";
import { getStrokeAnimations } from ".";
import { useTheme } from "@emotion/react";
import { Col } from "src/shoe-leather/components";

const Line: FC<{ lineTime?: number; lineLength?: number }> = ({
  lineTime = 0.5,
  lineLength = 100,
}) => {
  const theme = useTheme();
  const color = theme.colors.font;
  return useMemo(
    () => (
      <Col
        style={{
          flexShrink: 0,
          flexBasis: 0,
          flexGrow: 1,
          objectFit: "cover",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: 0,
            height: 3,
            position: "relative",
            justifyContent: "center",
            display: "flex",
            alignSelf: "center",
            top: "50%",
          }}
        >
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            height={3}
            viewBox={`0 0 ${lineLength * 2 + 1} 3`}
            fill="none"
            style={{
              //   flexGrow: 1,
              //   alignSelf: "center",
              objectFit: "cover",
              position: "absolute",
              //   overflow: "hidden",
            }}
          >
            <motion.path
              {...getStrokeAnimations({
                length: lineLength,
                delayAnimate: 0.2,
                delayExit: 0,
                duration: lineTime,
                easeAnimate: "easeIn",
                easeExit: "easeOut",
              })}
              d={`M${lineLength} 1L${lineLength * 2} 1`}
              stroke={color}
              strokeWidth={2}
              strokeLinecap="round"
            />
            <motion.path
              {...getStrokeAnimations({
                length: lineLength,
                delayAnimate: 0.2,
                delayExit: 0,
                duration: lineTime,
                easeAnimate: "easeIn",
                easeExit: "easeOut",
              })}
              d={`M${lineLength} 1L0 1`}
              stroke={color}
              strokeWidth={2}
              strokeLinecap="round"
            />
          </motion.svg>
        </div>
      </Col>
    ),
    [lineLength]
  );
};
export default Line;
