import { ThemeProvider, Global, Theme, Interpolation } from "@emotion/react";
import React, { ReactNode } from "react";
import { FC } from "react";
import {
  AppThemeType,
  BreakPointKey,
  BreakPoints,
  TypographyVariant,
  TypographyVariantType,
} from "./emotion";
const fonts = [
  {
    fontFamily: "Louis George Cafe",
    src: "url('/font/louis_george_cafe/Louis George Cafe.ttf')",
  },
  {
    fontFamily: "Imperator Bold",
    src: "url('/font/imperator/Imperator Bold.ttf')",
  },
];
export const typography: {
  [key in TypographyVariant]: TypographyVariantType;
} = {
  title: {
    type: "h1",
    fontSize: "56px",
    lineHeight: "72px",
    fontFamily: "Imperator Bold",
  },
  title2: {
    type: "h2",
    fontSize: "46px",
    lineHeight: "62px",
    fontFamily: "Imperator Bold",
  },
  title3: {
    type: "h3",
    fontSize: "30px",
    fontSizeMobile: "24px",
    lineHeight: "43px",
    fontFamily: "Imperator Bold",
  },
  link: {
    type: "span",
    fontSize: "21px",
    fontWeight: 100,
    // fontFamily: "Louis George Cafe",
    fontFamily: "Louis George Cafe",
  },
  body: {
    type: "span",
    fontSize: "18px",
    fontFamily: "Louis George Cafe",
  },
  code: {
    fontFamily: "monospace",
  },
};
const breakpoints: BreakPoints = {
  sm: 768,
};
const down = Object.fromEntries(
  Object.entries(breakpoints).map(([key, value]) => [
    key,
    `@media (max-width: ${(value as number) - 1}px)`,
  ])
) as Record<BreakPointKey, string>;
const up = Object.fromEntries(
  Object.entries(breakpoints).map(([key, value]) => [
    key,
    `@media (min-width: ${value as number}px)`,
  ])
) as Record<BreakPointKey, string>;
const theme: AppThemeType = {
  dimensions: {
    components: {
      default: {
        maxWidth: 900,
      },
      header: {
        height: 150,
      },
      footer: {
        height: 32,
      },
    },
    padding: {
      lg: 32,
      md: 16,
      sm: 8,
    },
  },
  breakpoints: {
    ...breakpoints,
    up,
    down,
  },
  typography,
  colors: {
    background: "#0f0f0f",
    backgroundCode: "#444444",
    font: "#f5f5f5",
    link: "#d6f6ff",
    neutral: {
      blue: "#0D374F",
      lightBlue: "#3174B2",
      red: "#912548",
      lightRed: "#ff54a3",
      green: "#136313",
      lightGreen: "#46cd46",
    },
  },
};
const globalStyles: Interpolation<Theme> = {
  ":root": {
    "--background": theme.colors.background,
    "--background-code": theme.colors.backgroundCode,
    "--font-color": theme.colors.font,
    "--font-color-link": theme.colors.link,
    "--font-size": theme.typography.body.fontSize,
    " --font-family": theme.typography.body.fontFamily,
    " --font-family-code": theme.typography.code.fontFamily,
    // "@media(prefers-color-scheme: light)": {
    //   "--font-color": theme.colors.background,
    //   "--background": theme.colors.font,
    // },
  },
  img: {
    width: "100%",
    WebkitUserDrag: "none",
  },
  "#root": {
    minHeight: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  code: {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
  body: {
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    flexGrow: 1,
  },
  a: {
    textDecoration: "none",
    color: "var(--font-color)",
  },
  "*": {
    userSelect: "none",
    boxSizing: "border-box",
    scrollbarWidth: "auto",
  },
  "*::-webkit-scrollbar": {
    width: "4px",
  },
  "*::-webkit-scrollbar-track": {},
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "var(--font-color)",
    borderRadius: "8px",
  },
};
const AppTheme: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      {fonts.map((font) => (
        <Global
          key={"font-face-" + new Date().getTime() + Math.random()}
          styles={{ "@font-face": font }}
        />
      ))}
      {children}
    </ThemeProvider>
  );
};
export default AppTheme;
