import React from "react";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { Row } from "src/shoe-leather/components";
import Title from "../ui/Title";
import GlassButton from "../ui/button/TextButton";
import { ROUTES } from "src/App";
import ParralaxItem from "../ui/effects/ParallaxItem";
import styled from "@emotion/styled";
import { Fade } from "../ui/animation";

const ButtonContainer = styled(ParralaxItem)({
  height: 74,
  zIndex: 3,
});
const WindowContainer = styled(Row)({
  height: 200,
});
const PageNotFound: FC = () => {
  const location = useLocation();
  return (
    <>
      <WindowContainer grow middle center>
        <Fade>
          <Title center>( •_•)</Title>
          <Title>Page Not Found</Title>
          <code>{location.pathname}</code>
        </Fade>
      </WindowContainer>

      <Row grow middle center>
        <ButtonContainer>
          <Row grow>
            <Fade>
              <GlassButton href={ROUTES.HOME}>Go Home</GlassButton>
            </Fade>
          </Row>
        </ButtonContainer>
      </Row>
    </>
  );
};
export default PageNotFound;
