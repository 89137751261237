import styled from "@emotion/styled";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import ImportedCard from "src/imported/DigitalBusinessCard/Card";
import { Fade } from "../ui/animation/";
import { Col, Row } from "src/shoe-leather/components";
import Typography from "src/shoe-leather/components/Typography";
import { keyframes } from "@emotion/react";
const Noverflow = styled(Col)({
  overflow: "visisble",
});
const bounceAnimation = keyframes`
    0%, 100% { transform: translateY(0); },
    50% { transform: translateY(10px); }
`;
const JustLanded = styled(Row)(({ theme }) => ({
  height: 0,
  marginTop: theme.dimensions.components.header.height,
  transition: "all 300ms ease",
  textShadow: `0 0 5px ${theme.colors.font}`,
  ":hover": {
    textShadow: `0 0 25px ${theme.colors.font}, 0 0 5px ${theme.colors.font}`,
  },
  animation: `${bounceAnimation} 1s infinite`,
}));
const Card: FC = () => {
  return (
    <Col grow center>
      <JustLanded center>
        <Link to="/home">
          <Col>
            <Row center>
              <Typography>^</Typography>
            </Row>
            <Typography>New Here?</Typography>
          </Col>
        </Link>
      </JustLanded>
      <Noverflow grow middle>
        <Fade duration={1}>
          <ImportedCard />
        </Fade>
      </Noverflow>
    </Col>
  );
};
export default Card;
