import React, { ForwardedRef, forwardRef } from "react";
import Leather, { LeatherProps } from "./Leather";

const FooterInner = (
  props: LeatherProps,
  ref: ForwardedRef<HTMLDivElement>
) => <Leather component="footer" {...props} ref={ref} />;

const Footer = forwardRef(FooterInner) as (
  props: LeatherProps & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof FooterInner>;
export default Footer;
