import React, { FC } from "react";

const HamburgerIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 54 54"
    fill="none"
  >
    <rect x="11" y="34" width="32" height="6" fill="#F5F5F5" />
    <rect x="11" y="14" width="32" height="6" fill="#F5F5F5" />
    <rect x="11" y="24" width="32" height="6" fill="#F5F5F5" />
  </svg>
);
export default HamburgerIcon;
