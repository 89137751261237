import styled from "@emotion/styled";
import React from "react";
import { FC } from "react";
import { Col } from "src/shoe-leather/components";

const StyledPill = styled(Col)(({ theme }) => ({
  borderRadius: 20,
  fontFamily: "monospace",
  padding: "0px 16px 4px",
  boxSizing: "border-box",
  whiteSpace: "nowrap",
  height: 'fit-content',
  margin: 1,
  background: theme.colors.neutral.blue + "aa",
  outline: `1px solid ${theme.colors.neutral.lightBlue}` + "aa",
  ":nth-of-type(3n + 2)": {
    background: theme.colors.neutral.green + "aa",
    outline: `1px solid ${theme.colors.neutral.lightGreen}` + "aa",
  },
  ":nth-of-type(3n + 3)": {
    background: theme.colors.neutral.red + "aa",
    outline: `1px solid ${theme.colors.neutral.lightRed}` + "aa",
  },
}));
const ProjectItemPill: FC<{ text?: string }> = ({ text = "" }) => {
  return <StyledPill>{text}</StyledPill>;
};

export default ProjectItemPill;
