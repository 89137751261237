import React, { ButtonHTMLAttributes, FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import {
  Blur,
  VerticalLines,
  HorizonalLines,
  Shine,
} from "../effects/SpanEffects";
import Button from "./Button";
import styled from "@emotion/styled";
export interface GlassButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string;
  children?: ReactNode;
}
const StyledButton = styled(Button)({
  maxWidth: 54,
});
const GlassButton: FC<GlassButtonProps> = ({ href, children, ...rest }) => {
  const renderedButton = (
    <StyledButton {...rest}>
      <Blur>
        <VerticalLines>
          <HorizonalLines>
            <Shine>{children}</Shine>
          </HorizonalLines>
        </VerticalLines>
      </Blur>
    </StyledButton>
  );
  if (href) {
    return <Link to={href}>{renderedButton}</Link>;
  }
  return renderedButton;
};
export default GlassButton;
