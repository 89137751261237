import React, { FC } from "react";

const QRCodeIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 54 54"
    fill="none"
  >
    <g clipPath="url(#clip0_103_784)">
      {/* <path d="M54 0H0V54H54V0Z" fill="#0F0F0F" /> */}
      <mask
        id="mask0_103_784"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="48"
        height="48"
      >
        <path
          d="M18.049 3.27271H16.3635V4.95816H18.049V3.27271Z"
          fill="white"
        />
        <path d="M31.14 3.27271H29.4546V4.95816H31.14V3.27271Z" fill="white" />
        <path
          d="M32.7763 3.27271H31.0908V4.95816H32.7763V3.27271Z"
          fill="white"
        />
        <path
          d="M34.4127 3.27271H32.7273V4.95816H34.4127V3.27271Z"
          fill="white"
        />
        <path d="M37.6855 3.27271H36V4.95816H37.6855V3.27271Z" fill="white" />
        <path
          d="M18.049 4.90906H16.3635V6.59451H18.049V4.90906Z"
          fill="white"
        />
        <path d="M19.6855 4.90906H18V6.59451H19.6855V4.90906Z" fill="white" />
        <path
          d="M21.3217 4.90906H19.6362V6.59451H21.3217V4.90906Z"
          fill="white"
        />
        <path
          d="M22.9582 4.90906H21.2727V6.59451H22.9582V4.90906Z"
          fill="white"
        />
        <path
          d="M26.2309 4.90906H24.5454V6.59451H26.2309V4.90906Z"
          fill="white"
        />
        <path
          d="M27.8673 4.90906H26.1819V6.59451H27.8673V4.90906Z"
          fill="white"
        />
        <path d="M31.14 4.90906H29.4546V6.59451H31.14V4.90906Z" fill="white" />
        <path
          d="M34.4127 4.90906H32.7273V6.59451H34.4127V4.90906Z"
          fill="white"
        />
        <path
          d="M36.049 4.90906H34.3635V6.59451H36.049V4.90906Z"
          fill="white"
        />
        <path
          d="M18.049 6.54541H16.3635V8.23086H18.049V6.54541Z"
          fill="white"
        />
        <path d="M19.6855 6.54541H18V8.23086H19.6855V6.54541Z" fill="white" />
        <path
          d="M21.3217 6.54541H19.6362V8.23086H21.3217V6.54541Z"
          fill="white"
        />
        <path
          d="M22.9582 6.54541H21.2727V8.23086H22.9582V6.54541Z"
          fill="white"
        />
        <path
          d="M27.8673 6.54541H26.1819V8.23086H27.8673V6.54541Z"
          fill="white"
        />
        <path
          d="M29.5036 6.54541H27.8181V8.23086H29.5036V6.54541Z"
          fill="white"
        />
        <path
          d="M34.4127 6.54541H32.7273V8.23086H34.4127V6.54541Z"
          fill="white"
        />
        <path
          d="M36.049 6.54541H34.3635V8.23086H36.049V6.54541Z"
          fill="white"
        />
        <path d="M37.6855 6.54541H36V8.23086H37.6855V6.54541Z" fill="white" />
        <path
          d="M24.5946 8.18182H22.9092V9.86728H24.5946V8.18182Z"
          fill="white"
        />
        <path
          d="M29.5036 8.18182H27.8181V9.86728H29.5036V8.18182Z"
          fill="white"
        />
        <path d="M31.14 8.18182H29.4546V9.86728H31.14V8.18182Z" fill="white" />
        <path
          d="M32.7763 8.18182H31.0908V9.86728H32.7763V8.18182Z"
          fill="white"
        />
        <path
          d="M36.049 8.18182H34.3635V9.86728H36.049V8.18182Z"
          fill="white"
        />
        <path d="M19.6855 9.81818H18V11.5036H19.6855V9.81818Z" fill="white" />
        <path
          d="M21.3217 9.81818H19.6362V11.5036H21.3217V9.81818Z"
          fill="white"
        />
        <path
          d="M24.5946 9.81818H22.9092V11.5036H24.5946V9.81818Z"
          fill="white"
        />
        <path
          d="M29.5036 9.81818H27.8181V11.5036H29.5036V9.81818Z"
          fill="white"
        />
        <path
          d="M34.4127 9.81818H32.7273V11.5036H34.4127V9.81818Z"
          fill="white"
        />
        <path
          d="M36.049 9.81818H34.3635V11.5036H36.049V9.81818Z"
          fill="white"
        />
        <path d="M37.6855 9.81818H36V11.5036H37.6855V9.81818Z" fill="white" />
        <path d="M18.049 11.4545H16.3635V13.14H18.049V11.4545Z" fill="white" />
        <path
          d="M21.3217 11.4545H19.6362V13.14H21.3217V11.4545Z"
          fill="white"
        />
        <path
          d="M22.9582 11.4545H21.2727V13.14H22.9582V11.4545Z"
          fill="white"
        />
        <path
          d="M24.5946 11.4545H22.9092V13.14H24.5946V11.4545Z"
          fill="white"
        />
        <path
          d="M27.8673 11.4545H26.1819V13.14H27.8673V11.4545Z"
          fill="white"
        />
        <path d="M31.14 11.4545H29.4546V13.14H31.14V11.4545Z" fill="white" />
        <path
          d="M34.4127 11.4545H32.7273V13.14H34.4127V11.4545Z"
          fill="white"
        />
        <path d="M37.6855 11.4545H36V13.14H37.6855V11.4545Z" fill="white" />
        <path
          d="M18.049 13.0909H16.3635V14.7763H18.049V13.0909Z"
          fill="white"
        />
        <path
          d="M21.3217 13.0909H19.6362V14.7763H21.3217V13.0909Z"
          fill="white"
        />
        <path
          d="M24.5946 13.0909H22.9092V14.7763H24.5946V13.0909Z"
          fill="white"
        />
        <path
          d="M27.8673 13.0909H26.1819V14.7763H27.8673V13.0909Z"
          fill="white"
        />
        <path d="M31.14 13.0909H29.4546V14.7763H31.14V13.0909Z" fill="white" />
        <path
          d="M34.4127 13.0909H32.7273V14.7763H34.4127V13.0909Z"
          fill="white"
        />
        <path d="M37.6855 13.0909H36V14.7763H37.6855V13.0909Z" fill="white" />
        <path
          d="M18.049 14.7272H16.3635V16.4127H18.049V14.7272Z"
          fill="white"
        />
        <path d="M19.6855 14.7272H18V16.4127H19.6855V14.7272Z" fill="white" />
        <path
          d="M21.3217 14.7272H19.6362V16.4127H21.3217V14.7272Z"
          fill="white"
        />
        <path
          d="M24.5946 14.7272H22.9092V16.4127H24.5946V14.7272Z"
          fill="white"
        />
        <path
          d="M29.5036 14.7272H27.8181V16.4127H29.5036V14.7272Z"
          fill="white"
        />
        <path
          d="M36.049 14.7272H34.3635V16.4127H36.049V14.7272Z"
          fill="white"
        />
        <path
          d="M8.23086 16.3636H6.54541V18.049H8.23086V16.3636Z"
          fill="white"
        />
        <path
          d="M9.86734 16.3636H8.18188V18.049H9.86734V16.3636Z"
          fill="white"
        />
        <path
          d="M11.5036 16.3636H9.81812V18.049H11.5036V16.3636Z"
          fill="white"
        />
        <path
          d="M14.7763 16.3636H13.0908V18.049H14.7763V16.3636Z"
          fill="white"
        />
        <path d="M18.049 16.3636H16.3635V18.049H18.049V16.3636Z" fill="white" />
        <path
          d="M26.2309 16.3636H24.5454V18.049H26.2309V16.3636Z"
          fill="white"
        />
        <path
          d="M29.5036 16.3636H27.8181V18.049H29.5036V16.3636Z"
          fill="white"
        />
        <path d="M31.14 16.3636H29.4546V18.049H31.14V16.3636Z" fill="white" />
        <path
          d="M34.4127 16.3636H32.7273V18.049H34.4127V16.3636Z"
          fill="white"
        />
        <path d="M37.6855 16.3636H36V18.049H37.6855V16.3636Z" fill="white" />
        <path
          d="M39.3217 16.3636H37.6362V18.049H39.3217V16.3636Z"
          fill="white"
        />
        <path
          d="M40.9582 16.3636H39.2727V18.049H40.9582V16.3636Z"
          fill="white"
        />
        <path
          d="M42.5946 16.3636H40.9092V18.049H42.5946V16.3636Z"
          fill="white"
        />
        <path
          d="M47.5036 16.3636H45.8181V18.049H47.5036V16.3636Z"
          fill="white"
        />
        <path d="M49.14 16.3636H47.4546V18.049H49.14V16.3636Z" fill="white" />
        <path
          d="M50.7763 16.3636H49.0908V18.049H50.7763V16.3636Z"
          fill="white"
        />
        <path d="M13.14 18H11.4546V19.6855H13.14V18Z" fill="white" />
        <path d="M16.4127 18H14.7273V19.6855H16.4127V18Z" fill="white" />
        <path d="M18.049 18H16.3635V19.6855H18.049V18Z" fill="white" />
        <path d="M19.6855 18H18V19.6855H19.6855V18Z" fill="white" />
        <path d="M21.3217 18H19.6362V19.6855H21.3217V18Z" fill="white" />
        <path d="M22.9582 18H21.2727V19.6855H22.9582V18Z" fill="white" />
        <path d="M26.2309 18H24.5454V19.6855H26.2309V18Z" fill="white" />
        <path d="M32.7763 18H31.0908V19.6855H32.7763V18Z" fill="white" />
        <path d="M39.3217 18H37.6362V19.6855H39.3217V18Z" fill="white" />
        <path d="M44.2309 18H42.5454V19.6855H44.2309V18Z" fill="white" />
        <path d="M45.8673 18H44.1819V19.6855H45.8673V18Z" fill="white" />
        <path d="M47.5036 18H45.8181V19.6855H47.5036V18Z" fill="white" />
        <path d="M50.7763 18H49.0908V19.6855H50.7763V18Z" fill="white" />
        <path
          d="M4.95816 19.6364H3.27271V21.3218H4.95816V19.6364Z"
          fill="white"
        />
        <path
          d="M6.59463 19.6364H4.90918V21.3218H6.59463V19.6364Z"
          fill="white"
        />
        <path
          d="M9.86734 19.6364H8.18188V21.3218H9.86734V19.6364Z"
          fill="white"
        />
        <path
          d="M14.7763 19.6364H13.0908V21.3218H14.7763V19.6364Z"
          fill="white"
        />
        <path
          d="M16.4127 19.6364H14.7273V21.3218H16.4127V19.6364Z"
          fill="white"
        />
        <path d="M19.6855 19.6364H18V21.3218H19.6855V19.6364Z" fill="white" />
        <path
          d="M22.9582 19.6364H21.2727V21.3218H22.9582V19.6364Z"
          fill="white"
        />
        <path
          d="M27.8673 19.6364H26.1819V21.3218H27.8673V19.6364Z"
          fill="white"
        />
        <path
          d="M29.5036 19.6364H27.8181V21.3218H29.5036V19.6364Z"
          fill="white"
        />
        <path d="M31.14 19.6364H29.4546V21.3218H31.14V19.6364Z" fill="white" />
        <path
          d="M32.7763 19.6364H31.0908V21.3218H32.7763V19.6364Z"
          fill="white"
        />
        <path
          d="M34.4127 19.6364H32.7273V21.3218H34.4127V19.6364Z"
          fill="white"
        />
        <path d="M37.6855 19.6364H36V21.3218H37.6855V19.6364Z" fill="white" />
        <path
          d="M39.3217 19.6364H37.6362V21.3218H39.3217V19.6364Z"
          fill="white"
        />
        <path
          d="M40.9582 19.6364H39.2727V21.3218H40.9582V19.6364Z"
          fill="white"
        />
        <path
          d="M42.5946 19.6364H40.9092V21.3218H42.5946V19.6364Z"
          fill="white"
        />
        <path
          d="M44.2309 19.6364H42.5454V21.3218H44.2309V19.6364Z"
          fill="white"
        />
        <path d="M13.14 21.2727H11.4546V22.9582H13.14V21.2727Z" fill="white" />
        <path d="M19.6855 21.2727H18V22.9582H19.6855V21.2727Z" fill="white" />
        <path
          d="M22.9582 21.2727H21.2727V22.9582H22.9582V21.2727Z"
          fill="white"
        />
        <path
          d="M26.2309 21.2727H24.5454V22.9582H26.2309V21.2727Z"
          fill="white"
        />
        <path
          d="M27.8673 21.2727H26.1819V22.9582H27.8673V21.2727Z"
          fill="white"
        />
        <path
          d="M39.3217 21.2727H37.6362V22.9582H39.3217V21.2727Z"
          fill="white"
        />
        <path
          d="M40.9582 21.2727H39.2727V22.9582H40.9582V21.2727Z"
          fill="white"
        />
        <path
          d="M42.5946 21.2727H40.9092V22.9582H42.5946V21.2727Z"
          fill="white"
        />
        <path d="M49.14 21.2727H47.4546V22.9582H49.14V21.2727Z" fill="white" />
        <path
          d="M8.23086 22.9091H6.54541V24.5945H8.23086V22.9091Z"
          fill="white"
        />
        <path
          d="M9.86734 22.9091H8.18188V24.5945H9.86734V22.9091Z"
          fill="white"
        />
        <path d="M13.14 22.9091H11.4546V24.5945H13.14V22.9091Z" fill="white" />
        <path
          d="M14.7763 22.9091H13.0908V24.5945H14.7763V22.9091Z"
          fill="white"
        />
        <path
          d="M16.4127 22.9091H14.7273V24.5945H16.4127V22.9091Z"
          fill="white"
        />
        <path
          d="M18.049 22.9091H16.3635V24.5945H18.049V22.9091Z"
          fill="white"
        />
        <path d="M19.6855 22.9091H18V24.5945H19.6855V22.9091Z" fill="white" />
        <path
          d="M22.9582 22.9091H21.2727V24.5945H22.9582V22.9091Z"
          fill="white"
        />
        <path
          d="M24.5946 22.9091H22.9092V24.5945H24.5946V22.9091Z"
          fill="white"
        />
        <path
          d="M26.2309 22.9091H24.5454V24.5945H26.2309V22.9091Z"
          fill="white"
        />
        <path
          d="M29.5036 22.9091H27.8181V24.5945H29.5036V22.9091Z"
          fill="white"
        />
        <path d="M31.14 22.9091H29.4546V24.5945H31.14V22.9091Z" fill="white" />
        <path
          d="M32.7763 22.9091H31.0908V24.5945H32.7763V22.9091Z"
          fill="white"
        />
        <path
          d="M34.4127 22.9091H32.7273V24.5945H34.4127V22.9091Z"
          fill="white"
        />
        <path d="M37.6855 22.9091H36V24.5945H37.6855V22.9091Z" fill="white" />
        <path
          d="M39.3217 22.9091H37.6362V24.5945H39.3217V22.9091Z"
          fill="white"
        />
        <path
          d="M42.5946 22.9091H40.9092V24.5945H42.5946V22.9091Z"
          fill="white"
        />
        <path
          d="M47.5036 22.9091H45.8181V24.5945H47.5036V22.9091Z"
          fill="white"
        />
        <path d="M49.14 22.9091H47.4546V24.5945H49.14V22.9091Z" fill="white" />
        <path
          d="M50.7763 22.9091H49.0908V24.5945H50.7763V22.9091Z"
          fill="white"
        />
        <path
          d="M6.59463 24.5454H4.90918V26.2309H6.59463V24.5454Z"
          fill="white"
        />
        <path
          d="M8.23086 24.5454H6.54541V26.2309H8.23086V24.5454Z"
          fill="white"
        />
        <path
          d="M11.5036 24.5454H9.81812V26.2309H11.5036V24.5454Z"
          fill="white"
        />
        <path d="M13.14 24.5454H11.4546V26.2309H13.14V24.5454Z" fill="white" />
        <path
          d="M16.4127 24.5454H14.7273V26.2309H16.4127V24.5454Z"
          fill="white"
        />
        <path
          d="M18.049 24.5454H16.3635V26.2309H18.049V24.5454Z"
          fill="white"
        />
        <path d="M19.6855 24.5454H18V26.2309H19.6855V24.5454Z" fill="white" />
        <path
          d="M26.2309 24.5454H24.5454V26.2309H26.2309V24.5454Z"
          fill="white"
        />
        <path
          d="M27.8673 24.5454H26.1819V26.2309H27.8673V24.5454Z"
          fill="white"
        />
        <path
          d="M29.5036 24.5454H27.8181V26.2309H29.5036V24.5454Z"
          fill="white"
        />
        <path d="M31.14 24.5454H29.4546V26.2309H31.14V24.5454Z" fill="white" />
        <path
          d="M32.7763 24.5454H31.0908V26.2309H32.7763V24.5454Z"
          fill="white"
        />
        <path
          d="M34.4127 24.5454H32.7273V26.2309H34.4127V24.5454Z"
          fill="white"
        />
        <path
          d="M36.049 24.5454H34.3635V26.2309H36.049V24.5454Z"
          fill="white"
        />
        <path d="M37.6855 24.5454H36V26.2309H37.6855V24.5454Z" fill="white" />
        <path
          d="M39.3217 24.5454H37.6362V26.2309H39.3217V24.5454Z"
          fill="white"
        />
        <path
          d="M40.9582 24.5454H39.2727V26.2309H40.9582V24.5454Z"
          fill="white"
        />
        <path
          d="M44.2309 24.5454H42.5454V26.2309H44.2309V24.5454Z"
          fill="white"
        />
        <path
          d="M45.8673 24.5454H44.1819V26.2309H45.8673V24.5454Z"
          fill="white"
        />
        <path d="M49.14 24.5454H47.4546V26.2309H49.14V24.5454Z" fill="white" />
        <path
          d="M50.7763 24.5454H49.0908V26.2309H50.7763V24.5454Z"
          fill="white"
        />
        <path
          d="M4.95816 26.1818H3.27271V27.8673H4.95816V26.1818Z"
          fill="white"
        />
        <path
          d="M6.59463 26.1818H4.90918V27.8673H6.59463V26.1818Z"
          fill="white"
        />
        <path
          d="M9.86734 26.1818H8.18188V27.8673H9.86734V26.1818Z"
          fill="white"
        />
        <path
          d="M14.7763 26.1818H13.0908V27.8673H14.7763V26.1818Z"
          fill="white"
        />
        <path d="M19.6855 26.1818H18V27.8673H19.6855V26.1818Z" fill="white" />
        <path
          d="M21.3217 26.1818H19.6362V27.8673H21.3217V26.1818Z"
          fill="white"
        />
        <path
          d="M24.5946 26.1818H22.9092V27.8673H24.5946V26.1818Z"
          fill="white"
        />
        <path
          d="M27.8673 26.1818H26.1819V27.8673H27.8673V26.1818Z"
          fill="white"
        />
        <path d="M31.14 26.1818H29.4546V27.8673H31.14V26.1818Z" fill="white" />
        <path
          d="M32.7763 26.1818H31.0908V27.8673H32.7763V26.1818Z"
          fill="white"
        />
        <path
          d="M34.4127 26.1818H32.7273V27.8673H34.4127V26.1818Z"
          fill="white"
        />
        <path d="M37.6855 26.1818H36V27.8673H37.6855V26.1818Z" fill="white" />
        <path
          d="M40.9582 26.1818H39.2727V27.8673H40.9582V26.1818Z"
          fill="white"
        />
        <path
          d="M42.5946 26.1818H40.9092V27.8673H42.5946V26.1818Z"
          fill="white"
        />
        <path
          d="M4.95816 27.8182H3.27271V29.5036H4.95816V27.8182Z"
          fill="white"
        />
        <path
          d="M6.59463 27.8182H4.90918V29.5036H6.59463V27.8182Z"
          fill="white"
        />
        <path d="M19.6855 27.8182H18V29.5036H19.6855V27.8182Z" fill="white" />
        <path
          d="M21.3217 27.8182H19.6362V29.5036H21.3217V27.8182Z"
          fill="white"
        />
        <path
          d="M22.9582 27.8182H21.2727V29.5036H22.9582V27.8182Z"
          fill="white"
        />
        <path
          d="M24.5946 27.8182H22.9092V29.5036H24.5946V27.8182Z"
          fill="white"
        />
        <path
          d="M26.2309 27.8182H24.5454V29.5036H26.2309V27.8182Z"
          fill="white"
        />
        <path
          d="M27.8673 27.8182H26.1819V29.5036H27.8673V27.8182Z"
          fill="white"
        />
        <path
          d="M29.5036 27.8182H27.8181V29.5036H29.5036V27.8182Z"
          fill="white"
        />
        <path
          d="M39.3217 27.8182H37.6362V29.5036H39.3217V27.8182Z"
          fill="white"
        />
        <path
          d="M40.9582 27.8182H39.2727V29.5036H40.9582V27.8182Z"
          fill="white"
        />
        <path
          d="M44.2309 27.8182H42.5454V29.5036H44.2309V27.8182Z"
          fill="white"
        />
        <path
          d="M45.8673 27.8182H44.1819V29.5036H45.8673V27.8182Z"
          fill="white"
        />
        <path
          d="M4.95816 29.4545H3.27271V31.14H4.95816V29.4545Z"
          fill="white"
        />
        <path
          d="M6.59463 29.4545H4.90918V31.14H6.59463V29.4545Z"
          fill="white"
        />
        <path
          d="M9.86734 29.4545H8.18188V31.14H9.86734V29.4545Z"
          fill="white"
        />
        <path
          d="M11.5036 29.4545H9.81812V31.14H11.5036V29.4545Z"
          fill="white"
        />
        <path
          d="M14.7763 29.4545H13.0908V31.14H14.7763V29.4545Z"
          fill="white"
        />
        <path d="M18.049 29.4545H16.3635V31.14H18.049V29.4545Z" fill="white" />
        <path
          d="M21.3217 29.4545H19.6362V31.14H21.3217V29.4545Z"
          fill="white"
        />
        <path
          d="M22.9582 29.4545H21.2727V31.14H22.9582V29.4545Z"
          fill="white"
        />
        <path
          d="M24.5946 29.4545H22.9092V31.14H24.5946V29.4545Z"
          fill="white"
        />
        <path d="M31.14 29.4545H29.4546V31.14H31.14V29.4545Z" fill="white" />
        <path
          d="M32.7763 29.4545H31.0908V31.14H32.7763V29.4545Z"
          fill="white"
        />
        <path
          d="M40.9582 29.4545H39.2727V31.14H40.9582V29.4545Z"
          fill="white"
        />
        <path
          d="M45.8673 29.4545H44.1819V31.14H45.8673V29.4545Z"
          fill="white"
        />
        <path
          d="M47.5036 29.4545H45.8181V31.14H47.5036V29.4545Z"
          fill="white"
        />
        <path
          d="M4.95816 31.0909H3.27271V32.7763H4.95816V31.0909Z"
          fill="white"
        />
        <path
          d="M16.4127 31.0909H14.7273V32.7763H16.4127V31.0909Z"
          fill="white"
        />
        <path
          d="M18.049 31.0909H16.3635V32.7763H18.049V31.0909Z"
          fill="white"
        />
        <path
          d="M21.3217 31.0909H19.6362V32.7763H21.3217V31.0909Z"
          fill="white"
        />
        <path
          d="M24.5946 31.0909H22.9092V32.7763H24.5946V31.0909Z"
          fill="white"
        />
        <path
          d="M26.2309 31.0909H24.5454V32.7763H26.2309V31.0909Z"
          fill="white"
        />
        <path
          d="M27.8673 31.0909H26.1819V32.7763H27.8673V31.0909Z"
          fill="white"
        />
        <path d="M31.14 31.0909H29.4546V32.7763H31.14V31.0909Z" fill="white" />
        <path d="M37.6855 31.0909H36V32.7763H37.6855V31.0909Z" fill="white" />
        <path
          d="M39.3217 31.0909H37.6362V32.7763H39.3217V31.0909Z"
          fill="white"
        />
        <path
          d="M42.5946 31.0909H40.9092V32.7763H42.5946V31.0909Z"
          fill="white"
        />
        <path
          d="M44.2309 31.0909H42.5454V32.7763H44.2309V31.0909Z"
          fill="white"
        />
        <path
          d="M45.8673 31.0909H44.1819V32.7763H45.8673V31.0909Z"
          fill="white"
        />
        <path
          d="M47.5036 31.0909H45.8181V32.7763H47.5036V31.0909Z"
          fill="white"
        />
        <path d="M49.14 31.0909H47.4546V32.7763H49.14V31.0909Z" fill="white" />
        <path
          d="M50.7763 31.0909H49.0908V32.7763H50.7763V31.0909Z"
          fill="white"
        />
        <path
          d="M4.95816 32.7272H3.27271V34.4127H4.95816V32.7272Z"
          fill="white"
        />
        <path
          d="M8.23086 32.7272H6.54541V34.4127H8.23086V32.7272Z"
          fill="white"
        />
        <path
          d="M9.86734 32.7272H8.18188V34.4127H9.86734V32.7272Z"
          fill="white"
        />
        <path
          d="M14.7763 32.7272H13.0908V34.4127H14.7763V32.7272Z"
          fill="white"
        />
        <path
          d="M18.049 32.7272H16.3635V34.4127H18.049V32.7272Z"
          fill="white"
        />
        <path
          d="M21.3217 32.7272H19.6362V34.4127H21.3217V32.7272Z"
          fill="white"
        />
        <path
          d="M22.9582 32.7272H21.2727V34.4127H22.9582V32.7272Z"
          fill="white"
        />
        <path
          d="M24.5946 32.7272H22.9092V34.4127H24.5946V32.7272Z"
          fill="white"
        />
        <path
          d="M26.2309 32.7272H24.5454V34.4127H26.2309V32.7272Z"
          fill="white"
        />
        <path d="M31.14 32.7272H29.4546V34.4127H31.14V32.7272Z" fill="white" />
        <path
          d="M32.7763 32.7272H31.0908V34.4127H32.7763V32.7272Z"
          fill="white"
        />
        <path
          d="M36.049 32.7272H34.3635V34.4127H36.049V32.7272Z"
          fill="white"
        />
        <path
          d="M40.9582 32.7272H39.2727V34.4127H40.9582V32.7272Z"
          fill="white"
        />
        <path
          d="M42.5946 32.7272H40.9092V34.4127H42.5946V32.7272Z"
          fill="white"
        />
        <path
          d="M47.5036 32.7272H45.8181V34.4127H47.5036V32.7272Z"
          fill="white"
        />
        <path
          d="M4.95816 34.3636H3.27271V36.049H4.95816V34.3636Z"
          fill="white"
        />
        <path
          d="M8.23086 34.3636H6.54541V36.049H8.23086V34.3636Z"
          fill="white"
        />
        <path
          d="M9.86734 34.3636H8.18188V36.049H9.86734V34.3636Z"
          fill="white"
        />
        <path
          d="M11.5036 34.3636H9.81812V36.049H11.5036V34.3636Z"
          fill="white"
        />
        <path d="M13.14 34.3636H11.4546V36.049H13.14V34.3636Z" fill="white" />
        <path
          d="M16.4127 34.3636H14.7273V36.049H16.4127V34.3636Z"
          fill="white"
        />
        <path d="M18.049 34.3636H16.3635V36.049H18.049V34.3636Z" fill="white" />
        <path d="M19.6855 34.3636H18V36.049H19.6855V34.3636Z" fill="white" />
        <path
          d="M24.5946 34.3636H22.9092V36.049H24.5946V34.3636Z"
          fill="white"
        />
        <path
          d="M26.2309 34.3636H24.5454V36.049H26.2309V34.3636Z"
          fill="white"
        />
        <path
          d="M27.8673 34.3636H26.1819V36.049H27.8673V34.3636Z"
          fill="white"
        />
        <path
          d="M29.5036 34.3636H27.8181V36.049H29.5036V34.3636Z"
          fill="white"
        />
        <path
          d="M32.7763 34.3636H31.0908V36.049H32.7763V34.3636Z"
          fill="white"
        />
        <path
          d="M34.4127 34.3636H32.7273V36.049H34.4127V34.3636Z"
          fill="white"
        />
        <path d="M36.049 34.3636H34.3635V36.049H36.049V34.3636Z" fill="white" />
        <path d="M37.6855 34.3636H36V36.049H37.6855V34.3636Z" fill="white" />
        <path
          d="M40.9582 34.3636H39.2727V36.049H40.9582V34.3636Z"
          fill="white"
        />
        <path
          d="M42.5946 34.3636H40.9092V36.049H42.5946V34.3636Z"
          fill="white"
        />
        <path
          d="M45.8673 34.3636H44.1819V36.049H45.8673V34.3636Z"
          fill="white"
        />
        <path d="M49.14 34.3636H47.4546V36.049H49.14V34.3636Z" fill="white" />
        <path
          d="M50.7763 34.3636H49.0908V36.049H50.7763V34.3636Z"
          fill="white"
        />
        <path d="M4.95816 36H3.27271V37.6855H4.95816V36Z" fill="white" />
        <path d="M8.23086 36H6.54541V37.6855H8.23086V36Z" fill="white" />
        <path d="M9.86734 36H8.18188V37.6855H9.86734V36Z" fill="white" />
        <path d="M11.5036 36H9.81812V37.6855H11.5036V36Z" fill="white" />
        <path d="M14.7763 36H13.0908V37.6855H14.7763V36Z" fill="white" />
        <path d="M21.3217 36H19.6362V37.6855H21.3217V36Z" fill="white" />
        <path d="M26.2309 36H24.5454V37.6855H26.2309V36Z" fill="white" />
        <path d="M27.8673 36H26.1819V37.6855H27.8673V36Z" fill="white" />
        <path d="M36.049 36H34.3635V37.6855H36.049V36Z" fill="white" />
        <path d="M37.6855 36H36V37.6855H37.6855V36Z" fill="white" />
        <path d="M39.3217 36H37.6362V37.6855H39.3217V36Z" fill="white" />
        <path d="M40.9582 36H39.2727V37.6855H40.9582V36Z" fill="white" />
        <path d="M42.5946 36H40.9092V37.6855H42.5946V36Z" fill="white" />
        <path d="M44.2309 36H42.5454V37.6855H44.2309V36Z" fill="white" />
        <path d="M47.5036 36H45.8181V37.6855H47.5036V36Z" fill="white" />
        <path d="M49.14 36H47.4546V37.6855H49.14V36Z" fill="white" />
        <path
          d="M18.049 37.6364H16.3635V39.3218H18.049V37.6364Z"
          fill="white"
        />
        <path d="M19.6855 37.6364H18V39.3218H19.6855V37.6364Z" fill="white" />
        <path
          d="M26.2309 37.6364H24.5454V39.3218H26.2309V37.6364Z"
          fill="white"
        />
        <path
          d="M27.8673 37.6364H26.1819V39.3218H27.8673V37.6364Z"
          fill="white"
        />
        <path
          d="M29.5036 37.6364H27.8181V39.3218H29.5036V37.6364Z"
          fill="white"
        />
        <path d="M31.14 37.6364H29.4546V39.3218H31.14V37.6364Z" fill="white" />
        <path
          d="M32.7763 37.6364H31.0908V39.3218H32.7763V37.6364Z"
          fill="white"
        />
        <path d="M37.6855 37.6364H36V39.3218H37.6855V37.6364Z" fill="white" />
        <path
          d="M44.2309 37.6364H42.5454V39.3218H44.2309V37.6364Z"
          fill="white"
        />
        <path
          d="M45.8673 37.6364H44.1819V39.3218H45.8673V37.6364Z"
          fill="white"
        />
        <path d="M49.14 37.6364H47.4546V39.3218H49.14V37.6364Z" fill="white" />
        <path
          d="M50.7763 37.6364H49.0908V39.3218H50.7763V37.6364Z"
          fill="white"
        />
        <path d="M19.6855 39.2727H18V40.9582H19.6855V39.2727Z" fill="white" />
        <path
          d="M22.9582 39.2727H21.2727V40.9582H22.9582V39.2727Z"
          fill="white"
        />
        <path
          d="M24.5946 39.2727H22.9092V40.9582H24.5946V39.2727Z"
          fill="white"
        />
        <path
          d="M27.8673 39.2727H26.1819V40.9582H27.8673V39.2727Z"
          fill="white"
        />
        <path
          d="M32.7763 39.2727H31.0908V40.9582H32.7763V39.2727Z"
          fill="white"
        />
        <path
          d="M36.049 39.2727H34.3635V40.9582H36.049V39.2727Z"
          fill="white"
        />
        <path d="M37.6855 39.2727H36V40.9582H37.6855V39.2727Z" fill="white" />
        <path
          d="M40.9582 39.2727H39.2727V40.9582H40.9582V39.2727Z"
          fill="white"
        />
        <path
          d="M44.2309 39.2727H42.5454V40.9582H44.2309V39.2727Z"
          fill="white"
        />
        <path
          d="M24.5946 40.9091H22.9092V42.5945H24.5946V40.9091Z"
          fill="white"
        />
        <path
          d="M26.2309 40.9091H24.5454V42.5945H26.2309V40.9091Z"
          fill="white"
        />
        <path
          d="M27.8673 40.9091H26.1819V42.5945H27.8673V40.9091Z"
          fill="white"
        />
        <path
          d="M29.5036 40.9091H27.8181V42.5945H29.5036V40.9091Z"
          fill="white"
        />
        <path d="M31.14 40.9091H29.4546V42.5945H31.14V40.9091Z" fill="white" />
        <path
          d="M32.7763 40.9091H31.0908V42.5945H32.7763V40.9091Z"
          fill="white"
        />
        <path
          d="M34.4127 40.9091H32.7273V42.5945H34.4127V40.9091Z"
          fill="white"
        />
        <path
          d="M36.049 40.9091H34.3635V42.5945H36.049V40.9091Z"
          fill="white"
        />
        <path d="M37.6855 40.9091H36V42.5945H37.6855V40.9091Z" fill="white" />
        <path
          d="M44.2309 40.9091H42.5454V42.5945H44.2309V40.9091Z"
          fill="white"
        />
        <path
          d="M45.8673 40.9091H44.1819V42.5945H45.8673V40.9091Z"
          fill="white"
        />
        <path d="M49.14 40.9091H47.4546V42.5945H49.14V40.9091Z" fill="white" />
        <path
          d="M50.7763 40.9091H49.0908V42.5945H50.7763V40.9091Z"
          fill="white"
        />
        <path
          d="M18.049 42.5454H16.3635V44.2309H18.049V42.5454Z"
          fill="white"
        />
        <path d="M19.6855 42.5454H18V44.2309H19.6855V42.5454Z" fill="white" />
        <path
          d="M21.3217 42.5454H19.6362V44.2309H21.3217V42.5454Z"
          fill="white"
        />
        <path
          d="M26.2309 42.5454H24.5454V44.2309H26.2309V42.5454Z"
          fill="white"
        />
        <path
          d="M32.7763 42.5454H31.0908V44.2309H32.7763V42.5454Z"
          fill="white"
        />
        <path
          d="M34.4127 42.5454H32.7273V44.2309H34.4127V42.5454Z"
          fill="white"
        />
        <path d="M37.6855 42.5454H36V44.2309H37.6855V42.5454Z" fill="white" />
        <path
          d="M39.3217 42.5454H37.6362V44.2309H39.3217V42.5454Z"
          fill="white"
        />
        <path
          d="M40.9582 42.5454H39.2727V44.2309H40.9582V42.5454Z"
          fill="white"
        />
        <path
          d="M42.5946 42.5454H40.9092V44.2309H42.5946V42.5454Z"
          fill="white"
        />
        <path
          d="M44.2309 42.5454H42.5454V44.2309H44.2309V42.5454Z"
          fill="white"
        />
        <path
          d="M45.8673 42.5454H44.1819V44.2309H45.8673V42.5454Z"
          fill="white"
        />
        <path
          d="M47.5036 42.5454H45.8181V44.2309H47.5036V42.5454Z"
          fill="white"
        />
        <path
          d="M18.049 44.1818H16.3635V45.8672H18.049V44.1818Z"
          fill="white"
        />
        <path d="M19.6855 44.1818H18V45.8672H19.6855V44.1818Z" fill="white" />
        <path
          d="M21.3217 44.1818H19.6362V45.8672H21.3217V44.1818Z"
          fill="white"
        />
        <path
          d="M24.5946 44.1818H22.9092V45.8672H24.5946V44.1818Z"
          fill="white"
        />
        <path
          d="M26.2309 44.1818H24.5454V45.8672H26.2309V44.1818Z"
          fill="white"
        />
        <path
          d="M27.8673 44.1818H26.1819V45.8672H27.8673V44.1818Z"
          fill="white"
        />
        <path
          d="M32.7763 44.1818H31.0908V45.8672H32.7763V44.1818Z"
          fill="white"
        />
        <path
          d="M36.049 44.1818H34.3635V45.8672H36.049V44.1818Z"
          fill="white"
        />
        <path d="M37.6855 44.1818H36V45.8672H37.6855V44.1818Z" fill="white" />
        <path
          d="M39.3217 44.1818H37.6362V45.8672H39.3217V44.1818Z"
          fill="white"
        />
        <path
          d="M42.5946 44.1818H40.9092V45.8672H42.5946V44.1818Z"
          fill="white"
        />
        <path
          d="M18.049 45.8182H16.3635V47.5036H18.049V45.8182Z"
          fill="white"
        />
        <path
          d="M21.3217 45.8182H19.6362V47.5036H21.3217V45.8182Z"
          fill="white"
        />
        <path
          d="M27.8673 45.8182H26.1819V47.5036H27.8673V45.8182Z"
          fill="white"
        />
        <path
          d="M29.5036 45.8182H27.8181V47.5036H29.5036V45.8182Z"
          fill="white"
        />
        <path
          d="M32.7763 45.8182H31.0908V47.5036H32.7763V45.8182Z"
          fill="white"
        />
        <path
          d="M36.049 45.8182H34.3635V47.5036H36.049V45.8182Z"
          fill="white"
        />
        <path d="M37.6855 45.8182H36V47.5036H37.6855V45.8182Z" fill="white" />
        <path
          d="M39.3217 45.8182H37.6362V47.5036H39.3217V45.8182Z"
          fill="white"
        />
        <path
          d="M40.9582 45.8182H39.2727V47.5036H40.9582V45.8182Z"
          fill="white"
        />
        <path
          d="M42.5946 45.8182H40.9092V47.5036H42.5946V45.8182Z"
          fill="white"
        />
        <path
          d="M44.2309 45.8182H42.5454V47.5036H44.2309V45.8182Z"
          fill="white"
        />
        <path
          d="M45.8673 45.8182H44.1819V47.5036H45.8673V45.8182Z"
          fill="white"
        />
        <path
          d="M47.5036 45.8182H45.8181V47.5036H47.5036V45.8182Z"
          fill="white"
        />
        <path d="M49.14 45.8182H47.4546V47.5036H49.14V45.8182Z" fill="white" />
        <path d="M19.6855 47.4545H18V49.14H19.6855V47.4545Z" fill="white" />
        <path
          d="M21.3217 47.4545H19.6362V49.14H21.3217V47.4545Z"
          fill="white"
        />
        <path
          d="M24.5946 47.4545H22.9092V49.14H24.5946V47.4545Z"
          fill="white"
        />
        <path
          d="M27.8673 47.4545H26.1819V49.14H27.8673V47.4545Z"
          fill="white"
        />
        <path
          d="M34.4127 47.4545H32.7273V49.14H34.4127V47.4545Z"
          fill="white"
        />
        <path d="M36.049 47.4545H34.3635V49.14H36.049V47.4545Z" fill="white" />
        <path
          d="M39.3217 47.4545H37.6362V49.14H39.3217V47.4545Z"
          fill="white"
        />
        <path
          d="M44.2309 47.4545H42.5454V49.14H44.2309V47.4545Z"
          fill="white"
        />
        <path
          d="M45.8673 47.4545H44.1819V49.14H45.8673V47.4545Z"
          fill="white"
        />
        <path d="M49.14 47.4545H47.4546V49.14H49.14V47.4545Z" fill="white" />
        <path d="M19.6855 49.0909H18V50.7763H19.6855V49.0909Z" fill="white" />
        <path
          d="M22.9582 49.0909H21.2727V50.7763H22.9582V49.0909Z"
          fill="white"
        />
        <path
          d="M24.5946 49.0909H22.9092V50.7763H24.5946V49.0909Z"
          fill="white"
        />
        <path
          d="M26.2309 49.0909H24.5454V50.7763H26.2309V49.0909Z"
          fill="white"
        />
        <path
          d="M27.8673 49.0909H26.1819V50.7763H27.8673V49.0909Z"
          fill="white"
        />
        <path d="M31.14 49.0909H29.4546V50.7763H31.14V49.0909Z" fill="white" />
        <path
          d="M34.4127 49.0909H32.7273V50.7763H34.4127V49.0909Z"
          fill="white"
        />
        <path
          d="M44.2309 49.0909H42.5454V50.7763H44.2309V49.0909Z"
          fill="white"
        />
        <path
          d="M47.5036 49.0909H45.8181V50.7763H47.5036V49.0909Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_103_784)">
        <path d="M54 0H0V54H54V0Z" fill="#F5F5F5" />
      </g>
      <path
        d="M3.27271 3.27271V14.7273H14.7273V3.27271H3.27271ZM13.0909 13.0909H4.90907V4.90907H13.0909V13.0909Z"
        fill="#F5F5F5"
      />
      <path
        d="M11.4545 6.54541H6.54541V11.4545H11.4545V6.54541Z"
        fill="#F5F5F5"
      />
      <path
        d="M39.2727 3.27271V14.7273H50.7273V3.27271H39.2727ZM49.0909 13.0909H40.9091V4.90907H49.0909V13.0909Z"
        fill="#F5F5F5"
      />
      <path
        d="M47.4545 6.54541H42.5454V11.4545H47.4545V6.54541Z"
        fill="#F5F5F5"
      />
      <path
        d="M3.27271 39.2727V50.7273H14.7273V39.2727H3.27271ZM13.0909 49.0909H4.90907V40.9091H13.0909V49.0909Z"
        fill="#F5F5F5"
      />
      <path
        d="M11.4545 42.5454H6.54541V47.4545H11.4545V42.5454Z"
        fill="#F5F5F5"
      />
    </g>
    <defs>
      <clipPath id="clip0_103_784">
        <rect width="54" height="54" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default QRCodeIcon;
