import React, { FC, ReactNode } from "react";

import { motion } from "framer-motion";
import useWindowSize from "src/hooks/useWindowSize";
import {
  getLineLength,
  getLineSpeed,
} from "src/components/ui/animated-svg/TextFrameLarge";
import { useLocation } from "react-router-dom";
import { ROUTES } from "src/App";
export interface WindowAnimationProps {
  from?: "bottom" | "middle";
  children: ReactNode;
}
const WindowAnimation: FC<WindowAnimationProps> = ({
  from = "middle",
  children,
}) => {
  const location = useLocation();
  const [innerWidth, _] = useWindowSize();

  const lineLength = getLineLength(innerWidth);
  const lineTime = lineLength / getLineSpeed(lineLength);

  const { pathname } = location;
  let delay = lineTime;
  switch (pathname) {
    case ROUTES.WEATHER:
      delay = 0;
      break;
    case ROUTES.PROJECTS:
      delay = 0;
      break;
  }

  let alignSelf = "center";
  switch (from) {
    case "bottom":
      alignSelf = "end";
      break;
  }
  const inital = {
    display: "flex",
    // "flex-direction": "column",
    flexGrow: 1,
    height: 0,
    overflow: "hidden",
    alignSelf: alignSelf,
    justifyContent: "center",
  };
  const animate = {
    height: "fit-content",
    transition: { ease: "easeOut", delay },
  };
  const exit = {
    height: 0,
  };

  return (
    <motion.div initial={inital} animate={animate} exit={exit}>
      {children}
    </motion.div>
  );
};
export default WindowAnimation;
