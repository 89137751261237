import styled from "@emotion/styled";
import React from "react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "src/shoe-leather/components";
import Typography from "src/shoe-leather/components/Typography";
import ProjectItemPill from "./ProjectItemPill";
import Line from "src/components/ui/animated-svg/Line";
import useWindowSize from "src/hooks/useWindowSize";
import { useTheme } from "@emotion/react";

const ProjectRow = styled(Row)(({ theme }) => ({
  padding: theme.dimensions.padding.md,
  gap: 24, //theme.dimensions.padding.lg,
  height: "fit-content",
  transition: "all 500ms ease, max-width 0ms",
  outline: "1px solid transparent",
  maxWidth: ` calc(100vw - ${theme.dimensions.padding.md}px)`,
  zIndex: 2,
  position: "relative",
  backdropFilter: "blur(0px)",
  [theme.breakpoints.up.sm]: {
    "&:hover": {
      background: "#ffffff0a",
      // background: "#0000005c",
      borderRadius: 8,
      outline: "1px solid #4F4F4F",
      backdropFilter: "blur(3px) saturate(.7)",
      "& img.thumbnail": {
        transform: "scale(1.2)",
      },
    },
  },
  [theme.breakpoints.down.sm]: {
    flexDirection: "column",
    gap: theme.dimensions.padding.md,
    margin: -theme.dimensions.padding.sm,
    padding: theme.dimensions.padding.sm,
  },
}));
const ProjectCol = styled(Col)(({ theme }) => ({
  gap: theme.dimensions.padding.md,
  [theme.breakpoints.down.sm]: {
    gap: theme.dimensions.padding.md,
  },
}));
const GappedCol = styled(Col)(({ theme }) => ({
  gap: theme.dimensions.padding.sm,
  [theme.breakpoints.down.sm]: {
    gap: theme.dimensions.padding.sm,
  },
}));
const ImageWrapper = styled(Col)(({ theme }) => ({
  borderRadius: 4,
  minWidth: 250,
  maxWidth: 250,
  height: 150,
  boxShadow: "0px 0px 25px rgba(0,0,0,.5)",
  overflow: "hidden",
  border: "1px solid rgba(0,0,0,.5)",
  [theme.breakpoints.down.sm]: {
    maxWidth: `calc(100vw - ${theme.dimensions.padding.lg}px)`,
    minWidth: "unset",
  },
  alignItems: "center",
  justifyContent: "center",
}));
const ImageStyled = styled("img")(({ theme }) => ({
  height: "100%",
  objectFit: "cover",
  objectPosition: "top",
  transition: "all 500ms ease",
  [theme.breakpoints.down.sm]: {
    objectPosition: "center",
  },
}));
const ProjectTitle = styled(Row)(({ theme }) => ({
  gap: theme.dimensions.padding.lg,
  [theme.breakpoints.down.sm]: {
    gap: theme.dimensions.padding.md,
  },
}));
const PillWrapper = styled(Row)(({ theme }) => ({
  gap: theme.dimensions.padding.md,
  maxWidth: "100%",
  overflowX: "hidden",
  padding: 1,
}));
const StyledLink = styled(Link)(({ theme }) => ({
  [theme.breakpoints.down.sm]: {
    paddingBottom: theme.dimensions.padding.lg,
    "&:not(:last-of-type)": {
      borderBottom: `1px solid rgba(255,255,255,.3)`,
    },
  },
}));


const GitHubLink = styled(Link)(({ theme }) => ({
  backgroundColor: "#1c1c1c",
  background: "linear-gradient(0deg,#1c1c1c,#474747)",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "row",
  outline: "1px solid transparent",
  padding: "8px",
  textDecoration: "none",
  transition: "all .5s ease",
  width: "fit-content",
  height: "fit-content",
  alignItems: "center",
  gap: "8px",
  'img': {
    width: "32px",
    height: "32px",
  },
  "&:hover": {
    background: "linear-gradient(0deg,#0f0f0f,#323232)",
    boxShadow: "0 3px 12px rgba(0,0,0,.35)",
    outline: "1px solid rgba(255,255,250,.631)",
    textShadow: "0 0 5px #fff",
  },
  [theme.breakpoints.down.sm]: {
    width: "100%",
    justifyContent: "center",
  },
}));

const ExtendedRow = styled(Row)(({ theme }) => ({
  [theme.breakpoints.down.sm]: {
    flexDirection: "column",
    gap: theme.dimensions.padding.md,
  },
}));
export type ProjectItemProps = {
  image: string;
  title: string;
  date: string;
  description: string;
  to: string;
  github?: string;
  pills: string[];
};
const ProjectItem: FC<ProjectItemProps> = ({
  image,
  title,
  date,
  to,
  github,
  description,
  pills,
}) => {
  const [innerWidth, _] = useWindowSize();
  const theme = useTheme();
  const summary =
    innerWidth < theme.breakpoints.sm ? description.split(".")[0] : description;
  return (
    <StyledLink to={to}>
      <ProjectRow>
        <ImageWrapper>
          <ImageStyled src={image} className="thumbnail" />
        </ImageWrapper>
        <ProjectCol grow>
          <Row wide>
            <GappedCol grow>
              <ProjectTitle wide>
                <Typography variant="title3">{title}</Typography>
                <Line lineLength={200} lineTime={0.4} />
                <Typography middle>{date}</Typography>
              </ProjectTitle>
              <Row>
                <Typography>{summary}</Typography>
              </Row>
            </GappedCol>
          </Row>
          <ExtendedRow spread >
            <PillWrapper wrap>
              {pills.map((pill) => (
                <ProjectItemPill key={title + pill} text={pill} />
              ))}
            </PillWrapper>
            {
              github && <GitHubLink to={github}>
                <img src="github-mark-white.svg" alt="GitHub" />
                GitHub
              </GitHubLink>
            }
          </ExtendedRow>
        </ProjectCol>
      </ProjectRow>
    </StyledLink>
  );
};
export default ProjectItem;
