import { useTheme } from "@emotion/react";
import { motion } from "framer-motion";
import React, { useMemo } from "react";
import { FC } from "react";
import useWindowSize from "src/hooks/useWindowSize";
import { getStrokeAnimations } from ".";

export const CURVE_WIDTH = 49;
export const CURVE_LENGTH = 80;
export const getLineSpeed = (lineLength: number): number => {
  return Math.max(lineLength, 450);
};
export const getLineLength = (
  innerWidth: number,
  gap: number,
  max: number
): number => {
  const maxSvgWidth = (Math.min(innerWidth, max) - gap) / 2;
  return Math.max(maxSvgWidth - CURVE_WIDTH, 1);
};
const TitleFrame: FC<{ right?: boolean; gap?: number; max?: number }> = ({
  right = false,
  gap = 166 + 64,
  max = undefined,
}) => {
  const [innerWidth, _] = useWindowSize();
  const theme = useTheme();
  const color = theme.colors.font;
  const lineLength = getLineLength(
    innerWidth,
    gap,
    max ?? theme.dimensions.components.default.maxWidth
  );
  const totalWidth = lineLength + CURVE_WIDTH + 3;

  const lineSpeed = getLineSpeed(lineLength);
  const lineTime = useMemo(() => lineLength / lineSpeed, []);
  const curveTime = useMemo(() => CURVE_LENGTH / lineSpeed, []);
  return useMemo(
    () => (
      <>
        <div
          style={{
            flexGrow: 1,
            transform: right ? "" : "scale(-1, 1)",
            display: "flex",
            flexDirection: "row-reverse",
            overflow: "hidden",
            alignSelf: "center",
          }}
        >
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width={totalWidth}
            height={98}
            viewBox={`0 0 ${totalWidth} 98`}
            fill="none"
            style={{
              flexGrow: 1,
              alignSelf: "center",
              minWidth: "fit-content",
            }}
          >
            <motion.path
              {...getStrokeAnimations({
                length: lineLength,
                delayAnimate: 0,
                delayExit: curveTime,
                duration: lineTime,
                easeAnimate: "easeIn",
                easeExit: "easeOut",
              })}
              d={`M0 49H${lineLength}`}
              stroke={color}
              strokeWidth={2}
              strokeLinecap="round"
            />
            <motion.path
              {...getStrokeAnimations({
                length: CURVE_LENGTH,
                delayAnimate: lineTime,
                delayExit: 0,
                duration: curveTime,
                easeAnimate: "easeOut",
                easeExit: "easeIn",
              })}
              d={`M${lineLength} 49C${371.148 - 333 + lineLength} 49 ${
                381.562 - 333 + lineLength
              } 17 ${382 - 333 + lineLength} 1`}
              stroke={color}
              strokeWidth={2}
              strokeLinecap="round"
            />
            <motion.path
              {...getStrokeAnimations({
                length: CURVE_LENGTH,
                delayAnimate: lineTime,
                delayExit: 0,
                duration: curveTime,
                easeAnimate: "easeOut",
                easeExit: "easeIn",
              })}
              d={`M${lineLength} 49C${371.148 - 333 + lineLength} 49 ${
                381.562 - 333 + lineLength
              } 81 ${382 - 333 + lineLength} 97`}
              stroke={color}
              strokeWidth={2}
              strokeLinecap="round"
            />
          </motion.svg>
        </div>
      </>
    ),
    [lineLength, innerWidth]
  );
};
export default TitleFrame;
